import { gql } from '@apollo/client';

export const BASIC_CLASSROOM_FRAG = gql`
  fragment BasicClassroomFrag on Classroom {
    id
    name
    school {
      id
      name
    }
    courses {
      product {
        id
        name
        payable
      }
      level
    }
    teachers {
      id
      name
      username
    }
    studentsLength
  }
`;

export const CLASSROOM_FRAG = gql`
  ${BASIC_CLASSROOM_FRAG}
  fragment ClassroomFrag on Classroom {
    ...BasicClassroomFrag
    students {
      id
      name
    }
    highScore
    mediumScore
    lowScore
  }
`;
