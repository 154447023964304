import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_VIDEO = gql`
  ${FRAG.VIDEO_FRAG}
  query SkWeb_GetVideo($fileId: ID!) {
    video(videoId: $fileId) {
      ...videoFrag
    }
  }
`;
