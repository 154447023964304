import { gql } from '@apollo/client';

export const GET_SCHOOLS = gql`
  query SkWeb_Schools_2 {
    schools {
      id
      name
    }
  }
`;
