import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_CHECK_STYLE_TRASH = gql`
  ${FRAG.STYLE_FRAG}
  ${FRAG.IMAGE_FRAG}
  query SkWeb_checkstyleTrash($parentId: ID!) {
    checkstyleTrash(sectionId: $parentId) {
      ...styleFrag
      _section
      image {
        ...imageFrag
      }
    }
  }
`;
