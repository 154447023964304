import { gql } from '@apollo/client';

export const GET_STUDENTS_FILTER = gql`
  query SkWeb_StudentsFilter($schoolId: ID) {
    classrooms(schoolId: $schoolId) {
      id
      name
    }
  }
`;
