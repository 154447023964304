import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_UNITTESTSCORES = gql`
  query SkWeb_testUnitScoreOfStudents(
    $classroomId: ID!
    $type: String!
    $unitId: ID!
  ) {
    testUnitScoreOfStudents(
      classroomId: $classroomId
      unitId: $unitId
      type: $type
    ) {
      id
      writtenTestScore
    }
  }
`;
