import { gql } from '@apollo/client';

export const GET_HOMEWORK_CLASSROOM = gql`
  query SkWeb_Classroom_4($classroomId: ID!) {
    classroom(classroomId: $classroomId) {
      id
      name
      students {
        id
        name
        username
      }
    }
  }
`;
