import axios from 'axios';

// utils
import { getConfig } from '../../utils/configHelpers';

const uploadserver = axios.create({
  // baseURL: `http://localhost:5001/skowt`, //test
  baseURL: `${getConfig('uploadserver')}/skowt`,
  // baseURL: getConfig("uploadserver", { mode: "dev" }),
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

uploadserver.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'accessToken',
    )}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export { uploadserver };
