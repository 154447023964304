import { gql } from '@apollo/client';

export const GET_STUDENTS_OF_SCHOOLS = gql`
  query SkWeb_Schools_3 {
    schools {
      students {
        id
      }
    }
  }
`;
