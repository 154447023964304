import { gql } from '@apollo/client';

export const GET_REPLYMESSAGES = gql`
  query SkWeb_ReplyMessages($messageId: ID!, $offset: Int, $limit: Int) {
    replyMessages(messageId: $messageId, offset: $offset, limit: $limit) {
      id
      createdAt
      date
      from {
        id
        name
      }
      replyContent
      isRead
      classrooms {
        id
        name
      }
      school {
        id
        name
      }
    }
  }
`;
