import USERTYPES from '../userTypes';
import defaultTheme from './defaultTheme';
import teacherTheme from './teacherTheme';
import principalTheme from './principalTheme';
import adminTheme from './adminTheme';
import superTheme from './superTheme';

export const themeSelector = (userType) => {
  switch (userType) {
    case USERTYPES.TEACHER:
      return teacherTheme;
    case USERTYPES.PRINCIPAL:
      return principalTheme;
    case USERTYPES.SALER:
      return adminTheme;
    case USERTYPES.EDITOR:
      return adminTheme;
    case USERTYPES.ADMIN:
      return adminTheme;
    case USERTYPES.SUPER:
      return superTheme;
    default:
      return defaultTheme;
  }
};
