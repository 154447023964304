import { gql } from '@apollo/client';
// import * as FRAG from '../../fragments';

export const GET_BOOKSETS = gql`
  query SkWeb_Booksets_2(
    $titleId: ID!
    $level: String
    $isEqual: Boolean
    $isHidden: Boolean
  ) {
    booksets(
      titleId: $titleId
      level: $level
      isEqual: $isEqual
      isHidden: $isHidden
    ) {
      id
      isHidden
      _title
      title
      level
    }
  }
`;
