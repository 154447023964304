import { gql } from '@apollo/client';

export const GET_INSIGHT_FILTER = gql`
  query SkWeb_InsightFilter {
    classrooms {
      id
      name
    }
    teachers {
      id
      name
    }
    schools {
      id
      name
    }
  }
`;
