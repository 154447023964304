import { gql } from '@apollo/client';

export const GET_ACTIVITY_NAME = gql`
  query SkWeb_GetActivityName($activityId: ID!) {
    activity(activityId: $activityId) {
      id
      title
      _title
      _bookset
      _book
      _unit
    }
  }
`;
