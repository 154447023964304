import { gql } from '@apollo/client';

export const GET_MESSAGES_LISTS = gql`
  query SkWeb_MessagesLists(
    $schoolId: ID
    $classroomId: ID
    $dateFrom: DateTime
    $dateTo: DateTime
    $offset: Int
    $limit: Int
  ) {
    messages(
      schoolId: $schoolId
      classroomId: $classroomId
      dateFrom: $dateFrom
      dateTo: $dateTo
      offset: $offset
      limit: $limit
    ) {
      id
      date
      school {
        id
        name
      }
      classrooms {
        id
        name
      }
      students {
        id
        name
      }
      target
      content
      isHidden
      usersRead
      replies
    }
  }
`;
