import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_UNITS = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_GetUnitdata($bookId: ID!, $sectionTypes: [String]) {
    units(bookId: $bookId) {
      id
      title
      _book
      sections(sectionTypes: $sectionTypes) {
        id
        activities {
          id
          title
        }
      }
      _sections
      noScore
      isHidden
      coverImage {
        ...coverimageFrag
      }
    }
  }
`;
