import { gql } from '@apollo/client';

export const GET_CHECKGRADE_EXCEL_HEADER = gql`
  query SkWeb_checkGradeExcelCommonHeader(
    $titleId: ID!
    $level: String!
    $activityType: String
  ) {
    checkGradeExcelCommonHeader(
      titleId: $titleId
      level: $level
      activityType: $activityType
    ) {
      id
      title
      booksetTitle
      unitTitles
    }
  }
`;
