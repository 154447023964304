import React, { createContext, useContext, useState, useRef } from 'react';

export const AudioContext = createContext();
export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context && typeof window !== 'undefined') {
    throw new Error(`useAudio must be used within a AudioContext `);
  }
  return context;
};

export const AudioProvider = ({ children }) => {
  const audio = useRef(new Audio());
  const [audioSrc, setAudioSrc] = useState();
  const [isPlayingAudios, setIsPlayingAudios] = useState(false);
  const [errorAudios, setErrorAudios] = useState([]);
  const [bgm, setBgm] = useState();
  // for removeEventListeners func
  const onPlayArr = useRef([]);
  const onPauseArr = useRef([]);
  const onEndedArr = useRef([]);
  // func
  const audioInit = () => {
    // for iOS-Specific Considerations, ref: https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
    audio.current.src =
      'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  };
  const removeEventListeners = () => {
    // Clean all previous event listeners to avoid repeat triggering
    for (const onPlay of onPlayArr.current)
      audio.current.removeEventListener('play', onPlay);
    for (const onPause of onPauseArr.current)
      audio.current.removeEventListener('pause', onPause);
    for (const onEnded of onEndedArr.current)
      audio.current.removeEventListener('ended', onEnded);
    onPlayArr.current = [];
    onPauseArr.current = [];
    onEndedArr.current = [];
  };
  const playAudio = ({
    url,
    onPlay = () => {},
    onPause = () => {},
    onEnded = () => {},
  }) => {
    if (!url) return;
    if (errorAudios.length > 0 && errorAudios.includes(url)) {
      onEnded(); // for Learn【word2】 keep going
      return;
    }
    // clean previous event listeners
    removeEventListeners();
    // new audio
    audio.current.src = url;
    audio.current.play().catch((error) => {
      // When uploading audio files fails, the 403 error is caught (DOMException: Failed to load because no supported source was found.)
      if (['NotSupportedError'].includes(error.name)) {
        setErrorAudios((prev) => prev.concat(audio.current.src));
        audio.current.pause();
        setIsPlayingAudios(false);
        onEnded(); // for Learn【word2】 keep going
      }
    });
    setAudioSrc(url);
    audio.current.addEventListener('play', onPlay);
    audio.current.addEventListener('pause', onPause);
    audio.current.addEventListener('ended', onEnded);
    onPlayArr.current.push(onPlay);
    onPauseArr.current.push(onPause);
    onEndedArr.current.push(onEnded);
  };

  const pauseAudio = () => {
    audio.current && audio.current.pause();
    setIsPlayingAudios(false);
  };

  const playAudiosInOrder = (props) => {
    const {
      urls,
      startIndex = 0,
      endIndex = 1,
      onPlay = () => {},
      onPause = () => {},
      onEnded = () => {},
      onFinally = () => {},
    } = props;

    playAudio({
      url: urls?.[startIndex],
      onPlay: () => {
        setIsPlayingAudios(true);
        onPlay(startIndex);
      },
      onPause: () => {
        setIsPlayingAudios(false);
        onPause();
      },
      onEnded: () => {
        onEnded(startIndex);
        if (startIndex + 1 < endIndex) {
          playAudiosInOrder({
            ...props,
            startIndex: startIndex + 1,
          });
        } else onFinally();
      },
    });
  };

  const playBgm = (url, volume) => {
    if (!url) return;
    bgm && bgm.pause();
    const newBgm = new Audio(url);
    if (typeof newBgm.loop == 'boolean') {
      newBgm.loop = true;
    } else {
      newBgm.addEventListener(
        'ended',
        function () {
          this.currentTime = 0;
          this.play();
        },
        false,
      );
    }
    newBgm.autoplay = true;
    newBgm.volume = volume ?? 0.05;
    newBgm.play();
    setBgm(newBgm);
  };

  const pauseBgm = () => {
    if (bgm?.play() !== undefined) {
      bgm
        ?.play()
        .then((_) => {
          bgm.pause();
        })
        .catch((error) => {
          console.log('[ error ]', error);
        });
    }
  };
  // main
  return (
    <AudioContext.Provider
      value={{
        audioSrc,
        audioInit,
        playAudio,
        pauseAudio,
        playAudiosInOrder,
        isPlayingAudios,
        setIsPlayingAudios,
        bgm,
        setBgm,
        playBgm,
        pauseBgm,
        errorAudios,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
