import { gql } from '@apollo/client';

export const GET_HOMEWORK_BOOKSETS = gql`
  query SkWeb_Booksets_4(
    $titleId: ID!
    $level: String
    $isWithoutLock: Boolean
  ) {
    booksets(titleId: $titleId, level: $level, isWithoutLock: $isWithoutLock) {
      id
      title
      level
    }
  }
`;
