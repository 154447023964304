import { gql } from '@apollo/client';

export const GET_ASSIGN_STUDENTS = gql`
  query SkWeb_Students_3($classroomId: ID) {
    students(classroomId: $classroomId, limit: 0, offset: 0) {
      id
      name
      username
      tickets {
        id
        name
        products {
          id
          name
        }
      }
    }
  }
`;
