import './configs/connect';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.override.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// context
import CommonProvider from './context/CommonContext';
// hooks
import { AuthProvider } from './hooks/authHooks';
import { DelayClickProvider } from './hooks/delayClickHooks';
import { CurrentProvider } from './hooks/currentHooks';
import { ConfigProvider } from './hooks/configHooks';
// components
import App from './App';
// configs
import { client } from './configs/server';

// material ui
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { adminThemeV5 } from './configs/themes/Theme';

// render react
ReactDOM.render(
  <CommonProvider>
    <ApolloProvider client={client}>
      <AuthProvider>
        <CurrentProvider>
          <DelayClickProvider>
            <ConfigProvider>
              <ThemeProviderV5 theme={adminThemeV5}>
                <App />
              </ThemeProviderV5>
            </ConfigProvider>
          </DelayClickProvider>
        </CurrentProvider>
      </AuthProvider>
    </ApolloProvider>
  </CommonProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
let refreshing = false;
serviceWorkerRegistration.register({
  onUpdate: () => {
    if (refreshing) {
      return;
    }

    refreshing = true;
    window.location.reload();
  },
});
