const defaultTheme = {
  primary: `#555555`,
  secondary: `#000000`,
  tertiary: `#aaaaaa`,
  quaternary: `#ffffff`,

  yellow: `#ffc700`,
  yellowSecondary: `#f7aa14`,
  yellowTertiary: `#fee500`,

  red: `#e63850`,
  redSecondary: `#e51e13`,
  redTertiary: `#ff4053`,

  green: `#28c985`,
  greenSecondary: `#13b06e`,
  greenTertiary: `#2ded9d`,

  blue: `#7ec9ff`,
  blueSecondary: `#68bffd`,
  blueTertiary: `#7eddff`,

  // text size
  bigTitle: `2.7rem`,
  title: `1.7rem`,
  content: `1.2rem`,
  bigcontent: `1.5rem`,
  category: `2rem`,
  count: `3.5rem`,
  listTitle: `1.4rem`,
  listContent: `1.4rem`,
};

export default defaultTheme;
