import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_AVAILABLE_LEVEL = gql`
  query SkWeb_UpgradeClassInfo(
    $classroomId: ID
    $productId: ID
    $level: String
  ) {
    upgradeClassInfo(
      classroomId: $classroomId
      productId: $productId
      level: $level
    ) {
      levels {
        name
        level
      }
      ownAvailableLevels {
        name
        level
      }
    }
  }
`;
