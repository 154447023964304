import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_BOOKS = gql`
  query SkWeb_BooksInBookset($booksetId: ID!) {
    books(booksetId: $booksetId) {
      id
      title
    }
  }
`;
