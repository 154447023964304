import { gql } from '@apollo/client';

export const GET_TEACHERLISTS = gql`
  query SkWeb_TeacherList(
    $limit: Int
    $offset: Int
    $tempPrincipalId: ID
    $schoolId: ID
  ) {
    teachers(
      limit: $limit
      offset: $offset
      tempPrincipalId: $tempPrincipalId
      schoolId: $schoolId
    ) {
      id
      name
      username
      products {
        id
        name
      }
      classroom {
        id
        name
        highScore
        mediumScore
        lowScore
        school {
          id
          name
        }
        studentsLength
      }
    }
  }
`;
