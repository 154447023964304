import { gql } from '@apollo/client';

export const GET_CONTENT = gql`
  query SkWeb_Contracts {
    contracts {
      id
      products {
        id
        name
        schools {
          id
          name
          startsAt
          endsAt
          remainingDays
        }
      }
    }
  }
`;
