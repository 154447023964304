import { gql } from '@apollo/client';

// frag
import { IMAGE_FRAG, AUDIO_FRAG, VIDEO_FRAG } from '../fragments';

export const LYRICS_FRAG = gql`
  fragment lyricsFrag on LyricsData {
    _img {
      id
      key
      domain
      thumbnail
      status
    }
    word
    isShowImg
  }
`;

export const STYLE_DATA_ARR_FRAG = gql`
  ${IMAGE_FRAG}
  ${AUDIO_FRAG}
  ${VIDEO_FRAG}
  fragment dataArrFrag on StyleData {
    id
    textData
    _audioData {
      ...audioFrag
    }
    _imageData {
      ...imageFrag
    }
    videoData
    _videoData {
      ...videoFrag
    }
  }
`;

export const STYLE_DATAS_FRAG = gql`
  ${STYLE_DATA_ARR_FRAG}
  ${LYRICS_FRAG}
  fragment datasFrag on StyleData {
    ...dataArrFrag

    lyricsData {
      ...lyricsFrag
    }
  }
`;

export const STYLE_QUESTIONS_FRAG = gql`
  ${STYLE_DATA_ARR_FRAG}
  fragment questionsFrag on StyleData {
    ...dataArrFrag
  }
`;

export const STYLE_OPTIONS_FRAG = gql`
  ${STYLE_DATA_ARR_FRAG}
  fragment optionsFrag on StyleData {
    ...dataArrFrag
  }
`;

export const STYLE_FRAG = gql`
  fragment styleFrag on Style {
    id
    title
    isHidden
    isStyleDoned
    styleType
    fontSize
  }
`;

export const STYLE_DETAIL_FRAG = gql`
  fragment styleDetailFrag on Style {
    id
    styleType
    _mainSkills
    _subSkills
    _title
    _bookset
    _book
    _unit
    _section
    fontSize
  }
`;

export const LEARN_DETAIL_FRAG = gql`
  ${STYLE_DETAIL_FRAG}
  ${STYLE_DATAS_FRAG}
  ${IMAGE_FRAG}
  fragment learnDetailFrag on Style {
    ...styleDetailFrag
    image {
      ...imageFrag
    }
    datas {
      ...datasFrag
      isDisableRecording
      autoplay
      recognize
      recognizeText
    }
    _activity
  }
`;

export const CHECK_DETAIL_FRAG = gql`
  ${STYLE_DETAIL_FRAG}
  ${STYLE_QUESTIONS_FRAG}
  ${STYLE_OPTIONS_FRAG}
  fragment checkDetailFrag on Style {
    ...styleDetailFrag

    questions {
      ...questionsFrag
    }
    options {
      ...optionsFrag
    }
    answers
  }
`;
