import { gql } from '@apollo/client';

export const GET_BOOKSET_NAMES = gql`
  query SkWeb_Booksets_1($titleId: ID!) {
    booksets(titleId: $titleId) {
      title
      id
    }
  }
`;
