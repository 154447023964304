import defaultTheme from './defaultTheme';

const adminTheme = {
  ...defaultTheme,
  // primary: defaultTheme.blue,
  // secondary: defaultTheme.blueSecondary,
  // tertiary: defaultTheme.blueTertiary,
};

export default adminTheme;
