import { gql } from '@apollo/client';

export const GET_BOOKSET_TRASH = gql`
  query SkWeb_booksetTrash($titleId: ID!) {
    booksetTrash(titleId: $titleId) {
      _title
      title
      id
      isHidden
      books {
        id
      }
    }
  }
`;
