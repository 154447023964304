import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_LEARN_STYLES = gql`
  ${FRAG.STYLE_FRAG}
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetLearnstylelist($parentId: [ID!]) {
    learnstyles(activityId: $parentId) {
      ...styleFrag
      image {
        ...imageFrag
      }
      _activity
    }
  }
`;
