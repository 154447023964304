import { gql } from '@apollo/client';

export const GET_STUDENTS = gql`
  query SkWeb_Students_5(
    $username: String
    $schoolId: ID
    $productId: ID
    $classroomId: ID
    $offset: Int
    $limit: Int
  ) {
    students(
      username: $username
      schoolId: $schoolId
      productId: $productId
      classroomId: $classroomId
      offset: $offset
      limit: $limit
    ) {
      id
      # userId
      name
      username
      englishName
      school {
        id
        name
      }
      product {
        id
        name
      }
      classroom {
        id
        name
        courses {
          product {
            id
            name
          }
          level
        }
      }
      hwDoneRate
      avgCheckGrade
      notDoneHW
      lastLogin
      coinInfo {
        coins
        totalUsedCoins
        currentCoins
      }
    }
  }
`;
