import { gql } from '@apollo/client';

export const GET_STUDENTS_TRANSACTIONS = gql`
  query SkWeb_Transactions(
    $schoolId: ID!
    $classroomId: ID
    $userId: ID
    $date: Date
    $limit: Int
    $offset: Int
  ) {
    transactions(
      schoolId: $schoolId
      classroomId: $classroomId
      userId: $userId
      date: $date
      limit: $limit
      offset: $offset
    ) {
      id
      amount
      createdAt
      updatedAt
      remark
      owner {
        id
        name
      }
      creator {
        id
        name
      }
    }
    numberOfTransactions(
      schoolId: $schoolId
      classroomId: $classroomId
      userId: $userId
    )
  }
`;
