import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_UNIT_TRASH = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_unitTrash($bookId: ID!) {
    unitTrash(bookId: $bookId) {
      title
      id
      _book
      _sections
      isHidden
      coverImage {
        ...coverimageFrag
      }
    }
  }
`;
