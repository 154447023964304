import { gql } from '@apollo/client';

export const GET_STUDENTSUBSKILLS = gql`
  query SkWeb_SubSkills($studentId: ID, $mainSkillIds: [ID!]) {
    subSkills(studentId: $studentId, mainSkillIds: $mainSkillIds) {
      id
      name
      score
    }
  }
`;
