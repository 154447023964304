import { gql } from '@apollo/client';

export const GET_CLASSROOMS = gql`
  query SkWeb_Classrooms_2(
    $schoolId: ID
    $teacherId: ID
    $limit: Int
    $offset: Int
  ) {
    classrooms(
      schoolId: $schoolId
      teacherId: $teacherId
      limit: $limit
      offset: $offset
    ) {
      id
      name
      school {
        id
        name
      }
      courses {
        level
        product {
          id
          name
          payable
        }
      }
    }
  }
`;
