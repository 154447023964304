import { gql } from '@apollo/client';

export const GET_CHECKHOMEWORKS = gql`
  query SkWeb_CheckHomeworks($assignmentId: ID!, $limit: Int, $offset: Int) {
    checkHomeworks(
      assignmentId: $assignmentId
      limit: $limit
      offset: $offset
    ) {
      id
      type
      _styles
      student {
        id
        name
        username
      }
      hwGrade
      progress
      dueDate
      doneDate
      makeUp
    }
  }
`;
