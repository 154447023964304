import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_LEARN_STYLE_TRASH = gql`
  ${FRAG.STYLE_FRAG}
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetLearnstyleTrash($parentId: ID!) {
    learnstyleTrash(activityId: $parentId) {
      ...styleFrag
      _activity
      datas {
        id
      }
      image {
        ...imageFrag
      }
    }
  }
`;
