import { gql } from '@apollo/client';

export const GET_CLASSROOMS_OPTIONS = gql`
  query SkWeb_Classrooms_10(
    $teacherId: ID
    $schoolId: ID
    $limit: Int
    $offset: Int
    $productId: ID
  ) {
    classrooms(
      teacherId: $teacherId
      schoolId: $schoolId
      limit: $limit
      offset: $offset
      productId: $productId
    ) {
      id
      name
    }
  }
`;
