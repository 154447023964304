import { gql } from '@apollo/client';

export const GET_MAINSKILLS = gql`
  query SkWeb_GetMainSkills {
    mainSkills {
      id
      name
      _subSkills
      subSkillname {
        id
        name
      }
    }
  }
`;
