import { gql } from '@apollo/client';

export const GET_STUDENTMAINSKILLS = gql`
  query SkWeb_MainSkills($studentId: ID) {
    mainSkills(studentId: $studentId) {
      id
      name
      score
    }
  }
`;
