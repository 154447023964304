import { createContext, useContext, useRef } from 'react';

export const CommonContext = createContext({
  classesSchoolIdRef: null,
});

const CommonProvider = ({ children }) => {
  const classesSchoolIdRef = useRef('');

  return (
    <CommonContext.Provider
      value={{
        classesSchoolIdRef,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
export default CommonProvider;

export const useCommonContext = () => {
  return useContext(CommonContext);
};
