import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_TITLE_TRASH = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_GetTitletrashdata {
    titlesTrash {
      id
      title
      coverImage {
        ...coverimageFrag
      }
      isHidden
      isTrash
    }
  }
`;
