import { gql } from '@apollo/client';

export const GET_HOMEWORK_FILTERBYCLASS = gql`
  query SkWeb_Classrooms_6 {
    classrooms {
      id
      name
      school {
        id
        name
      }
    }
  }
`;
