import { gql } from '@apollo/client';

export const GET_SKILLS_TRASH = gql`
  query SkWeb_GetTrashSkills {
    skillsTrash {
      id
      name
      isTrash
      isDeleted
      _subSkills
      trashSubSkillname {
        id
        name
      }
    }
  }
`;
