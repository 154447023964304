import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_VIDEOS = gql`
  ${FRAG.VIDEO_FRAG}
  query SkWeb_GetVideos(
    $titleId: ID
    $booksetId: ID
    $bookId: ID
    $unitId: ID
  ) {
    videos(
      titleId: $titleId
      booksetId: $booksetId
      bookId: $bookId
      unitId: $unitId
    ) {
      ...videoFrag
    }
  }
`;
