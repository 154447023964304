import { gql } from '@apollo/client';

export const GET_TITLE = gql`
  query SkWeb_GetTitle($userId: ID!, $titleId: ID) {
    studentState(userId: $userId, titleId: $titleId) {
      title {
        id
        title
        _booksets
      }
    }
  }
`;
