import React, { createContext, useContext, useState } from 'react';
// utils
import { getConfig } from '../utils/configHelpers';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [value] = useState(() => ({ getConfig }));

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
