const userTypes = {
  // melody
  MELODY: 'melody',
  SUPER: 'super',
  ADMIN: 'admin',
  DEVELOPER: 'developer',
  EDITOR: 'editor',
  SALES_REP: 'sales_rep',
  CUSTOMER_SERVICE_REP: 'customer_service_rep',
  EDUCATIONAL_CONS: 'educational_cons',
  EDITABLE: ['super', 'admin', 'developer', 'editor', 'educational_cons'],

  // customer
  CUSTOMER: 'customer',
  PRINCIPAL: 'principal',
  SECRETARY: 'secretary',
  TEACHER: 'teacher',
  STUDENT: 'student',
};

export default userTypes;
