import { gql } from '@apollo/client';

export const GET_CHECKCLASSROOMHOMEWORK = gql`
  query SkWeb_ClassroomHomework($offset: Int, $limit: Int, $schoolId: ID) {
    classroomHomework(offset: $offset, limit: $limit, schoolId: $schoolId) {
      id
      name
      school {
        id
        name
      }
      teachers {
        id
        name
        username
      }
      courses {
        level
        product {
          id
          name
        }
      }
      studentsLength
      undoneHomeworks
    }
  }
`;
