import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isEmpty } from 'lodash-es';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { useAlert } from 'react-alert';
import SignInDialog from '../utils/authHelpers/SignInDialog';

const SIGNIN_WITH_CODE = gql`
  mutation SkWeb_Signin($code: String!) {
    signinWithCode(code: $code) {
      accessToken
      id
      username
      name
      userId
      email
      type
      group
      role
      avatarUri
      response {
        status
        success
        message
      }
      editableProductIds
    }
  }
`;

export const useSigninWithCode = () => {
  const [signinWithCode] = useMutation(SIGNIN_WITH_CODE, {});
  const alert = useAlert();

  return useCallback(
    async (code) => {
      try {
        const res = await signinWithCode({ variables: { code } });

        localStorage.setItem(
          'accessToken',
          res.data.signinWithCode?.accessToken,
        );
        return res;
      } catch (error) {
        alert.error('No authorization ');
      }
    },
    [signinWithCode, alert],
  );
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState();
  const [userIdMyBestUser, setUserIdMyBestUser] = useState();

  const [auth, setAuth] = useState('loading');
  const [role, setRole] = useState();
  const [roleMyBestUser, setRoleMyBestUser] = useState();
  const [userType, setUserType] = useState();
  const [tempType, setTempType] = useState();
  const [name, setName] = useState();
  const [nameMyBestUser, setNameMyBestUser] = useState();
  const [username, setUsername] = useState();
  const [usernameMyBestUser, setUsernameMyBestUser] = useState();
  const [tempId, setTempId] = useState();
  const [avatarUri, setAvatarUri] = useState();
  const [userClassrooms, setUserClassrooms] = useState();
  const [userSchools, setUserSchools] = useState();
  const [userProducts, setUserProducts] = useState();
  const [userEditableProductIds, setUserEditableProductIds] = useState();
  const [
    userEditableProductIdsMyBestUser,
    setUserEditableProductIdsMyBestUser,
  ] = useState();

  useEffect(() => {
    if (nameMyBestUser && nameMyBestUser !== name) {
      setName(nameMyBestUser);
    }
  }, [name, nameMyBestUser]);

  useEffect(() => {
    if (usernameMyBestUser && usernameMyBestUser !== username) {
      setUsername(usernameMyBestUser);
    }
  }, [username, usernameMyBestUser]);

  useEffect(() => {
    if (roleMyBestUser && roleMyBestUser !== role) {
      setRole(roleMyBestUser);
    }
  }, [role, roleMyBestUser]);

  useEffect(() => {
    if (userIdMyBestUser && userIdMyBestUser !== userId) {
      setUserId(userIdMyBestUser);
    }
  }, [userId, userIdMyBestUser]);

  useEffect(() => {
    if (
      userEditableProductIdsMyBestUser &&
      userEditableProductIdsMyBestUser !== userEditableProductIds
    ) {
      setUserEditableProductIds(userEditableProductIdsMyBestUser);
    }
  }, [userEditableProductIds, userEditableProductIdsMyBestUser]);

  useEffect(() => {
    if (role) {
      // ========= temporary method to deal with userType =========
      let type = undefined;
      switch (role) {
        case 'super':
        case 'admin':
        case 'developer':
        case 'principal':
          type = role;
          break;
        case 'secretary':
        case 'teacher':
          type = isEmpty(userEditableProductIds) ? 'teacher' : 'admin'; // for other company's cooperation who can edit
          break;
        case 'editor':
        case 'educational_cons':
        case 'sales_rep':
        case 'customer_service_rep':
          type = 'admin';
          break;
        default:
      }

      // ========= temporary method to deal with userType =========
      setUserType(type?.toLowerCase());
      setTempType(type?.toLowerCase());
    }
  }, [role, userEditableProductIds]);

  return (
    <MyBestUserSigninProvider>
      <AuthContext.Provider
        value={{
          auth,
          setAuth,
          role,
          setRole,
          userType,
          setUserType,
          tempType,
          setTempType,
          name,
          setName,
          username,
          setUsername,
          avatarUri,
          setAvatarUri,
          userId,
          setUserId,
          tempId,
          setTempId,
          userClassrooms,
          setUserClassrooms,
          userSchools,
          setUserSchools,
          userProducts,
          setUserProducts,
          userEditableProductIds,
          setUserEditableProductIds,
          nameMyBestUser,
          setNameMyBestUser,
          usernameMyBestUser,
          setUsernameMyBestUser,
          roleMyBestUser,
          setRoleMyBestUser,
          userIdMyBestUser,
          setUserIdMyBestUser,
          userEditableProductIdsMyBestUser,
          setUserEditableProductIdsMyBestUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    </MyBestUserSigninProvider>
  );
};

export const useUserId = () => [
  useContext(AuthContext).userId,
  useContext(AuthContext).setUserId,
];

export const useAuth = () => [
  useContext(AuthContext).auth,
  useContext(AuthContext).setAuth,
];

export const useRole = () => [
  useContext(AuthContext).role,
  useContext(AuthContext).setRole,
];

export const useUserType = () => [
  useContext(AuthContext).userType,
  useContext(AuthContext).setUserType,
];

export const useTempType = () => [
  useContext(AuthContext).tempType,
  useContext(AuthContext).setTempType,
];

export const useTempId = () => [
  useContext(AuthContext).tempId,
  useContext(AuthContext).setTempId,
];

export const useName = () => [
  useContext(AuthContext).name,
  useContext(AuthContext).setName,
];
export const useAvatarUri = () => [
  useContext(AuthContext).avatarUri,
  useContext(AuthContext).setAvatarUri,
];

export const useUsername = () => [
  useContext(AuthContext).username,
  useContext(AuthContext).setUsername,
];
export const useUserClassrooms = () => [
  useContext(AuthContext).userClassrooms,
  useContext(AuthContext).setUserClassrooms,
];
export const useUserSchools = () => [
  useContext(AuthContext).userSchools,
  useContext(AuthContext).setUserSchools,
];
export const useUserProducts = () => [
  useContext(AuthContext).userProducts,
  useContext(AuthContext).setUserProducts,
];
export const useUserEditableProductIds = () => [
  useContext(AuthContext).userEditableProductIds,
  useContext(AuthContext).setUserEditableProductIds,
];
export const useNameMyBestUser = () => [
  useContext(AuthContext).nameMyBestUser,
  useContext(AuthContext).setNameMyBestUser,
];
export const useUsernameMyBestUser = () => [
  useContext(AuthContext).usernameMyBestUser,
  useContext(AuthContext).setUsernameMyBestUser,
];
export const useRoleMyBestUser = () => [
  useContext(AuthContext).roleMyBestUser,
  useContext(AuthContext).setRoleMyBestUser,
];
export const useUserIdMyBestUser = () => [
  useContext(AuthContext).userIdMyBestUser,
  useContext(AuthContext).setUserIdMyBestUser,
];
export const useUserEditableProductIdsMyBestUser = () => [
  useContext(AuthContext).userEditableProductIdsMyBestUser,
  useContext(AuthContext).setUserEditableProductIdsMyBestUser,
];

export const useSignout = () => {
  const [, setUserId] = useUserId();

  // const client = useApolloClient();
  const [, setAuth] = useAuth();
  const [, setRole] = useRole();
  const [, setUserType] = useUserType();
  const [, setName] = useName();
  const [, setAvatarUri] = useAvatarUri();
  const [, setUsername] = useUsername();
  const [, setUserClassrooms] = useUserClassrooms();
  const [, setUserSchools] = useUserSchools();
  const [, setUserProducts] = useUserProducts();
  const [, setUserEditableProductIds] = useUserEditableProductIds();

  const history = useHistory();

  const signout = useCallback(() => {
    history.push('/');
    // client.clearStore();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('server');

    setRole(null);
    setUserType(null);
    setName(null);
    setAvatarUri(null);
    setUsername(null);
    setAuth('fail');
    setUserId(null);
    setUserClassrooms(null);
    setUserSchools(null);
    setUserProducts(null);
    setUserEditableProductIds(null);
  }, [
    //client,
    setAuth,
    setRole,
    setUserType,
    history,
    setUserId,
    setName,
    setUsername,
    setAvatarUri,
    setUserClassrooms,
    setUserSchools,
    setUserProducts,
    setUserEditableProductIds,
  ]);

  return signout;
};

export const useAuthChecker = () => (typeRequire, Component) => {
  const Authenticate = (props) => {
    // hooks
    const signout = useSignout();
    const [auth] = useAuth();
    const [userType] = useUserType();
    const [tempType] = useTempType();

    useEffect(() => {
      auth === 'fail' && signout();
    }, [auth, signout]);

    return auth === 'pass' && userType ? (
      userType === 'admin' ? (
        <Component {...props} />
      ) : [userType, tempType].includes(typeRequire) ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/${userType}/`} />
      )
    ) : null;
  };

  return Authenticate;
};

export const MyBestUserSigninContext = createContext();

export const MyBestUserSigninProvider = ({ children }) => {
  const [open, setOpen] = useState(null);

  const onClose = useCallback(() => setOpen(null), []);

  const signin = useCallback((src) => setOpen(src), []);

  return (
    <MyBestUserSigninContext.Provider value={signin}>
      {children}
      <SignInDialog src={open} onClose={onClose} />
    </MyBestUserSigninContext.Provider>
  );
};

export const useMyBestUserSignin = () => useContext(MyBestUserSigninContext);
