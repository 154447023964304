/* eslint-disable no-useless-concat */
//copy from my best user
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { sify, tify } from 'chinese-conv';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const language = localStorage.getItem('language');
    if (language) return language;
    if (navigator.language)
      switch (navigator.language.toLowerCase()) {
        case 'zh-cn':
          return 'cn';
        case 'zh-tw':
        case 'zh-hk':
          return 'tw';
        default:
          return 'en';
      }
    return 'en';
  });

  const [value, setValue] = useState(() => ({
    language,
    changeLanguage(language) {
      localStorage.setItem('language', language);
      setLanguage(language);
    },
  }));

  useEffect(() => {
    setValue((value) => ({ ...value, language }));
  }, [language]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

const noScript = { en: 'No Script', tw: '文本缺失' };

export const useScriptConverter = () => {
  const { language } = useLanguage();
  return useCallback(
    (script) => {
      const helper = (script) =>
        script
          ? typeof script === 'string'
            ? script
            : script[language] ||
              (language === 'cn' && script.tw
                ? typeof script.tw === 'function'
                  ? (...args) => sify(script.tw(...args))
                  : sify(script.tw)
                : language === 'tw' && script.cn
                ? typeof script.cn === 'function'
                  ? (...args) => tify(script.cn(...args))
                  : tify(script.cn)
                : script.en || helper(noScript))
          : helper(noScript);
      return helper(script);
    },
    [language],
  );
};

export const createScripts = (converter) =>
  new Proxy(
    {},
    {
      get: (o, k) => (k in o ? o[k] : converter(noScript)),
      set: (target, prop, value) => {
        target[prop] = converter(value);
        return true;
      },
    },
  );

export const makeScripts =
  (rawScripts = {}) =>
  () => {
    const converter = useScriptConverter();
    return useMemo(() => {
      const scripts = createScripts(converter);
      for (const key in rawScripts) scripts[key] = rawScripts[key];
      return scripts;
    }, [converter]);
  };

export const useScripts = makeScripts({
  abcs: { en: "ABC's", tw: 'ABC' },
  abilityDetail: { en: 'Ability Detail', tw: '能力數值' },
  abilityRadarChart: { en: 'Ability Radar Chart', tw: '能力分布' },
  add: { en: 'Add', tw: '新增' },
  all: { en: 'All', tw: '全部' },
  allRecords: { en: 'All records', tw: '全部紀錄' },
  action: { en: 'Bulk action', tw: '動作' },
  activity: { en: 'Activity', tw: '活動' },
  activityType: { en: 'Activity Type', tw: '活動類型' },
  activatedApp: { en: 'Activated App', tw: '已啟用App項目' },
  addNewClassroom: { en: 'Add New Classroom', tw: '加新班級' },
  addStudents: { en: 'Add students', tw: '加學生' },
  addStudentsToClass: { en: 'Add Students to Classroom', tw: '加學生進班級' },
  addTeacher: { en: 'Add Teacher to Class', tw: '新增老師到班級' },
  addedSuccess: { en: 'added Success', tw: '新增成功' },
  addMakeUpHWSuccess: {
    en: 'add makeup homework success',
    tw: '新增補考成功',
  },
  addingMakeUpHW: { en: 'Adding makeup homework', tw: '新增補考任務中' },
  adminHome: { en: 'Admin Home', tw: '管理者 首頁' },
  aboutToEnd: { en: 'About to end', tw: '即將過期' },
  amount: { en: 'Amount', tw: '數量' },
  animation: { en: 'Animations', tw: '動畫' },
  anotherClass: { en: 'another class', tw: '其他班級' },
  answeredRecords: { en: 'Answered Records', tw: '已作答紀錄' },
  apply: { en: 'Apply', tw: '執行' },
  applyRemoveStudent: { en: 'Remove students', tw: '移除學生' },
  assignedBy: { en: 'Assigned By', tw: '指派人' },
  assignedDate: { en: 'Assigned Date', tw: '指派日期' },
  assignedTo: { en: 'Assigned To', tw: '指派給' },
  assignHomework: { en: 'AssignHomework', tw: '指派任務' },
  assignHomeworkDetail: {
    en: 'Assign homework to students',
    tw: '指派任務給學生',
  },
  analysisCorrentViode: {
    en: `Analysis on the correct rate of foreign teacher's video`,
    tw: `外師影片觀看分析率`,
  },
  analysisCorrentLearn: {
    en: `Analysis on the correct rate of self-learning`,
    tw: `Word & Sentences答題分析率`,
  },
  analysisCorrentCheck: {
    en: `Analysis on the correct rate of Self-practice`,
    tw: `自我練習答題分析率`,
  },
  analysisCorrentFinal: {
    en: `Analysis on the correct rate of final test`,
    tw: `最終測驗分析率`,
  },
  scoreLess60: {
    en: `Score is less than 60`,
    tw: `成績低於60`,
  },
  scoreBetween60_80: {
    en: `Score is between 60-80`,
    tw: `成績介於60-80`,
  },
  scoreOver80: {
    en: `Score is over 80`,
    tw: `成績高於80`,
  },
  progressLess60: {
    en: `Progress is less than 60%`,
    tw: `完成進度低於60%`,
  },
  progressBetween60_80: {
    en: `Progress is between 60-80%`,
    tw: `完成進度介於60-80%`,
  },
  progressOver80: {
    en: `Progress is over 80%`,
    tw: `完成進度高於80%`,
  },
  assessScore: { en: 'Assess Score', tw: '成績輸入與查詢' },
  assessScoreDetail: { en: 'Assess students score', tw: '輸入學生的成績' },
  assignedHomeworkRecord: {
    en: 'Assigned Homework Record',
    tw: '指派任務紀錄',
  },
  assignTo: {
    en: 'Assign To',
    tw: '指派給',
  },
  audioLibrary: { en: 'Audio Library', tw: '音檔庫' },
  autoPlay: { en: 'AutoPlay', tw: '自動播放' },
  autoPlayInterval: {
    en: 'AutoPlay Interval (s)',
    tw: '自動播放間隔 (秒)',
  },
  average: { en: 'AVERAGE', tw: '平均' },
  averageCH: {
    en: 'Average score for Check and Homework',
    tw: '測驗和任務平均成績',
  },
  book: { en: 'Book', tw: '書' },
  bookset: { en: 'Bookset', tw: '書集合' },
  cancel: { en: 'Cancel', tw: '取消' },
  calcdescription: {
    en: 'Calculation method description:',
    tw: '進度條計算方式:',
  },
  category: { en: 'Category ', tw: '類別' },
  change: { en: 'Change', tw: '修改' },
  changeClassContentType: {
    en: 'Change class content type',
    tw: '修改班級教材',
  },
  changeFrom: {
    en: 'Change from',
    tw: '舊教材',
  },
  changeTo: { en: 'Change to', tw: '新教材' },
  check: { en: 'Check', tw: '測驗' },
  checkGrade: { en: 'Check Grades', tw: '學習紀錄' },
  checkGradeDetail: { en: 'Students Check Grades', tw: '學生們的學習紀錄' },
  checkGradeClassroom: {
    en: 'Check Grades Classroom Detail ',
    tw: '班級學習紀錄細節',
  },
  checkHomework: { en: 'Check Homework', tw: '本日任務' },
  checkInternet: { en: 'Checking internet', tw: '檢查網路連線' },
  checkSelectedMusic: {
    en: 'Please check selected music or click to rename file',
    tw: '請確認您要上傳的音樂 或 點選改檔名',
  },
  checkStats: { en: 'Stats', tw: '狀態' },
  chooseClassroom: { en: 'Choose your classroom', tw: '選擇班級' },
  chooseAvailableLevel: { en: 'Choose an available level', tw: '選擇可用等級' },
  chooseProduct: { en: 'Choose Your Product', tw: '選擇產品(教材)' },
  chooseSchool: { en: 'Choose your school', tw: '選擇學校' },
  chooseStudent: { en: 'Choose Your Multiple Student', tw: '選擇學生' },
  chooseTeacher: { en: 'Choose the teacher you want to add', tw: '選擇老師' },
  chooseThumbnail: { en: 'Please choose a thumbnail', tw: '請選擇一張縮圖' },
  class: { en: 'class', tw: '班級' },
  class2: { en: 'Class', tw: '班級' },
  classes: { en: 'Classes', tw: '班級' },
  classesDetail: { en: 'Classes detail', tw: '班級的詳細資料' },
  classroom: { en: 'Classroom', tw: '班級' },
  classrooms: { en: 'Classrooms', tw: '班級' },
  classroomName: { en: 'Class Name', tw: '班級名稱' },
  clear: { en: 'Clear', tw: '清除' },
  close: { en: 'Close', tw: '關閉' },
  coins: { en: 'Coins', tw: '金幣' },
  numberOfGoldCoins: { en: 'Number of gold coins', tw: '金幣數量' },
  coinsAreRequired: { en: 'Coins are required!', tw: '金幣必填' },
  coinsAreNotEnough: { en: 'Coins are not enough!', tw: '金幣不足' },
  comprehensiveQuestionType: {
    en: 'comprehensive question type',
    tw: '綜合題型',
  },
  content: { en: 'content type', tw: '產品(教材)' },
  contentType: { en: 'Content Type', tw: '教材' },
  createClass: { en: 'Create Class', tw: '新增班級' },
  correctAnswer: { en: 'Correct Answer', tw: '正確答案' },
  creator: { en: 'Creator', tw: '兌換負責人' },
  current: { en: 'Current', tw: '目前' },
  drama: { en: 'Drama', tw: '戲劇' },
  dashboard: { en: 'Dashboard', tw: '首頁' },
  dateError: { en: 'Please enter the correct time', tw: '請輸入正確時間' },
  delete: { en: 'Delete', tw: '刪除' },
  deleteClassroom: { en: 'Delete Classroom', tw: '刪除班級' },
  detail: { en: 'Detail', tw: '詳細資料' },
  discard: { en: 'DISCARD', tw: '放棄' },
  done: { en: 'Done', tw: '已完成' },
  due: { en: 'Due', tw: '已過期' },
  startDate: { en: 'Start Date', tw: '起始日期' },
  dueDate: { en: 'Due Date', tw: '截止日期' },
  edit: { en: 'Edit', tw: '編輯' },
  editAudio: { en: 'Editing audio', tw: '編輯音檔' },
  editDate: { en: 'Editing date', tw: '編輯日期' },
  editDateSuccess: { en: 'Editing date success', tw: '編輯日期成功' },
  editDateFailed: { en: 'Editing date failed', tw: '編輯日期失敗' },
  editImage: { en: 'Editing image', tw: '編輯圖片' },
  editHighlight: { en: 'Editing Highlight', tw: '編輯重點' },
  editRemarkSuccess: { en: 'Editing remark success.', tw: '編輯兌換備註成功' },
  editRemarkFailed: { en: 'Editing remark failed.', tw: '編輯兌換備註失敗' },
  editVideo: { en: 'Editing video', tw: '編輯影片' },
  enterNewName: { en: 'Enter the new name', tw: '輸入新名稱' },
  enterText: { en: 'Enter Text', tw: '輸入文字' },
  error: { en: 'Sorry, there are no information.', tw: '查無資料' },
  errorNoClass: { en: 'No selected classroom', tw: '沒選擇班級' },
  excelLoading: { en: 'Excel Loading...', tw: 'Excel 加載中...' },
  exchange: { en: 'Exchange', tw: '兌換' },
  exchangeCoins: { en: 'Exchange Coins', tw: '金幣使用' },
  exchangeSuccess: { en: 'Exchange success.', tw: '兌換成功' },
  exchangeFailed: { en: 'Exchange failed.', tw: '兌換失敗' },
  ending: { en: 'Ending', tw: '結尾' },
  filter: { en: 'Filter', tw: '過濾' },
  filterHWByDate: { en: 'Filter homework by date range', tw: '以日期過濾任務' },
  filterByDate: { en: 'Filter by date', tw: '以日期過濾' },
  finalTest: { en: 'Final Test', tw: '考試卷成績' },
  finish: { en: 'Finished', tw: '已完成' },
  finishedDate: { en: 'Finished Date', tw: '完成日期' },
  completionProgress: {
    en: 'Progress',
    tw: '完成進度',
  },
  fromDate: { en: 'From date', tw: '起始日期' },
  games: { en: 'Games', tw: '遊戲' },
  green: { en: 'Green', tw: '長條綠' },
  greenlight: { en: 'Green Light', tw: '綠色燈' },
  goBackToLastPage: { en: 'Go back to last page', tw: '返回上一頁' },
  rowsPerPage: { en: 'Rows per page', tw: '每頁數量' },
  hello: { en: 'Hello', tw: '歡迎' },
  hide: { en: 'Hide', tw: '隱藏' },
  highlights: { en: 'Highlights', tw: '重點' },
  hint: {
    en: 'To send a message to 1) all the students in a class(es), select the class(es). 2) only specific student(s), select the class and then the students.',
    tw: '寄送訊息給 特定班級 內全部學生請 選學校和班級, 特定學生 請再多選學生',
  },
  home: { en: 'HOME', tw: '首頁' },
  homework: { en: 'Homework', tw: '任務' },
  homeworkStatus: { en: 'Homework Status', tw: '是否完成' }, //任務狀態
  homeworkData: { en: 'Homework Data', tw: '任務資料' },
  homeworkDetail: { en: 'Homework Detail', tw: '任務詳細資料' },
  classHomework: { en: 'Class Homework', tw: '班級任務' },
  studentHomework: { en: 'Student Homework', tw: '學生任務' },
  homeworkTypeAndCount: { en: 'Type And Question(s)', tw: '任務類型及題數' },
  imageLibrary: { en: 'Image Library', tw: '圖片庫' },
  inProgress: { en: 'In progress', tw: '進行中' },
  insight: { en: 'Insight', tw: '學習成效' },
  excludeFromGrading: {
    en: 'Exclude from grading.',
    tw: '不列入成績計算',
  },
  landingLogo: {
    en: '孩子能帶著走的英語老師',
    tw: '孩子能帶著走的英語老師',
  },
  landingSlogan11: {
    en: '鞏固孩子',
    tw: `鞏固孩子`,
  },
  landingSlogan12: {
    en: '學習成效',
    tw: '學習成效',
  },
  landingSlogan21: {
    en: '鍛鍊孩子',
    tw: '鍛鍊孩子',
  },
  landingSlogan22: {
    en: '語音語調',
    tw: '語音語調',
  },
  landingSlogan31: {
    en: '培養孩子',
    tw: '培養孩子',
  },
  landingSlogan32: {
    en: '獨立學習',
    tw: '獨立學習',
  },
  landingCheck: {
    en: '海量習題快速驗收孩子學習成效',
    tw: '海量習題快速驗收孩子學習成效',
  },
  landingAudio: { en: '環保愛地球,音檔隨時聽', tw: '環保愛地球,音檔隨時聽' },
  landingTask: {
    en: '老師能客製化布置學習任務',
    tw: '老師能客製化布置學習任務',
  },
  landingUser: { en: '快速瀏覽班級使用狀況', tw: '快速瀏覽班級使用狀況' },
  landingLearn: {
    en: `觀看專業教學視頻與跟讀,先暖身熟悉語感 `,
    tw: `觀看專業教學視頻與跟讀,先暖身熟悉語感 `,
  },
  landingLearn2: {
    en: ` 先聽音,換孩子錄音,通過微軟語音識別鍛鍊孩子語音語調`,
    tw: ` 先聽音,換孩子錄音,通過微軟語音識別鍛鍊孩子語音語調`,
  },
  landingParent1: {
    en: ' -直方圖顯示學生學習進度',
    tw: ' -直方圖顯示學生學習進度',
  },
  landingParent2: {
    en: ' -網狀圖數據分析學生學習成效',
    tw: ' -網狀圖數據分析學生學習成效',
  },
  landingParent3: {
    en: '-孩子的學習狀況一目了然',
    tw: '-孩子的學習狀況一目了然',
  },
  landingParent4: {
    en: '-更有親師留言板雙向溝通鞏固孩子健全的學習環境',
    tw: '-更有親師留言板雙向溝通鞏固孩子健全的學習環境',
  },
  language: {
    en: 'Language',
    tw: '語言',
  },
  late: {
    en: 'Late',
    tw: '遲交',
  },
  learn: { en: 'Learn', tw: '練習' },
  learning_videos: { en: 'Learning Videos', tw: '練習影片' },
  linkToTransactions: {
    en: 'Link to Transactions Page',
    tw: '連結到 兌換紀錄 頁面',
  },

  asker: { en: 'Asker:', tw: '提問者' },
  answerer: { en: 'Answerer:', tw: '答題者' },
  image: { en: 'Image', tw: '圖片' },
  audio: { en: 'Audio', tw: '音檔' },
  autoplay: { en: 'AutoPlay', tw: '自動播放' },
  record: { en: 'Record', tw: '錄音' },
  recognize: { en: 'Recognize', tw: '辨識' },
  text: { en: 'Text', tw: '文字' },
  editText: { en: 'Edit Text', tw: '修改文字' },
  textPlaceholder: { en: 'Enter your Text...', tw: '請輸入文字...' },
  type: { en: 'Type', tw: '類型' },
  article: { en: 'Article', tw: '文章' },
  articlePlaceholder: { en: 'Enter your article...', tw: '請輸入文章...' },
  questionPlaceholder: { en: 'Enter your question...', tw: '請輸入題目...' },
  optionPlaceholder: { en: 'Enter your option...', tw: '請輸入選項...' },
  answerPlaceholder: { en: 'Enter your answer...', tw: '請輸入答案...' },
  answerInOrderPlaceholder: {
    en: 'Put your options in order...',
    tw: '請依照正確答案的順序填入選項...',
  },
  dropAnswerPlaceholder: { en: 'Select a answer...', tw: '選擇一個答案...' },
  answer123Placeholder: { en: 'Select 1-3', tw: '選擇1-3' },
  recognizePlaceholder: {
    en: 'Enter your recognize text...',
    tw: '請輸入欲辨識文字...',
  },
  startAnswer: { en: 'Start', tw: '填入答案' },
  finishAnswer: { en: 'Finish', tw: '完成' },

  level: { en: 'Level', tw: '等級' },
  levelBookset: { en: 'Level/Bookset', tw: '等級/書籍' },
  listening: { en: 'Listening', tw: '聽力' },
  loading: { en: 'Loading...', tw: '讀取中...' },
  loadingText: {
    en: 'Please select above information...',
    tw: '請選擇以上資訊',
  },
  loadMore: { en: 'Load More', tw: '更多' },
  loadMoreAlert: { en: 'No more datas', tw: '沒有更多資料' },
  logic: { en: 'Logic', tw: '邏輯思考' },
  logInWithMyBestUser: { en: 'Log In ', tw: '登入' },
  makeUp: { en: 'Make-up', tw: '補考' },
  media: { en: 'Media', tw: '影音' },

  mediaTitleImage: { en: 'Image', tw: '張圖片' },
  mediaTitleVideo: { en: 'Video', tw: '個影片' },
  mediaTitleAudio: { en: 'Audio', tw: '個音檔' },
  mediaTitleUpload: { en: 'upload', tw: '上傳' },
  mediaTitleInput: { en: 'input', tw: '輸入' },
  mediaHaveNot: { en: 'You have not', tw: '您尚未' },
  mediaQuestionNot: { en: 'You have not input', tw: '您尚未 輸入' },
  mediaQuestionAns: { en: 'Answer', tw: '個答案' },
  mediaTitleOption: { en: 'Option', tw: '個選項' },
  mediaTitleChatBox: { en: 'ChatBox', tw: '組對話' },
  mediaTitleArticle: { en: 'Article', tw: '篇文章' },
  mediaTitleText: { en: 'Text', tw: '段文字' },
  mediaTitleLyric: { en: 'Lyric', tw: '段歌詞' },
  mediaTitleRecText: { en: 'Recogizable Text', tw: '可辨識文字' },
  mediaDupeAns: {
    en: 'You need to choose a different answer .',
    tw: '您必須選擇不同答案。',
  },

  message: { en: 'message', tw: '訊息' },
  modalTitle: { en: 'Modal title', tw: '標題' },
  move: { en: 'Move', tw: '移動' },
  moveAllStudentsTo: { en: 'Move all students to', tw: '移動全部學生到' },
  moveStudents: {
    en: 'Move students to another class',
    tw: '移動學生到其他班級',
  },
  music: { en: 'Music', tw: '音樂' },
  name: { en: 'Name', tw: '名稱' },
  studentName: { en: 'Student Name', tw: '學生姓名' },
  newClassroomName: { en: 'Fill In Your Classroom Name', tw: '填寫新班級名稱' },
  numberOfstudents: { en: 'Number of students', tw: '學生數量' },
  no: { en: 'No', tw: '否' },
  noAvailableLevel: { en: 'No Available Level!', tw: '尚無可使用的等級!' },
  noData: { en: 'Sorry, there are no information.', tw: '查無資料' },
  notDone: { en: 'Not Done', tw: '未完成' },
  notDoneOverdue: { en: 'NotDoneOverdue', tw: '未完成未過期' },
  notActivate: {
    en: 'Students who do not activate the ticket can not be assigned.',
    tw: '未啟用授權碼的學生無法收到新的指派任務',
  },
  notFound: { en: 'Not Found', tw: '找不到頁面' },
  notStart: { en: 'Not Start', tw: '未開始' },
  number: { en: 'Number', tw: '編號' },
  offline: { en: 'OFFline', tw: '未連線' },
  ok: { en: 'OK', tw: '確定' },
  online: { en: 'Online', tw: '連線中' },
  orange: { en: 'Orange', tw: '長條橘' },

  password: { en: 'Password', tw: '密碼' },
  path: { en: 'Path', tw: '題目路徑' },
  people: { en: 'People', tw: '學生人數' },
  personalCheckGrade: { en: 'Personal Check Grades', tw: '個人自主學習紀錄' },
  playground: { en: 'Playground', tw: '遊戲' },
  product: { en: 'Product', tw: '產品(教材)' },
  progress: { en: 'progress', tw: '進度' },
  question: { en: 'Question', tw: '題目' },
  questions: { en: 'Questions', tw: '題' },
  questionsCount: { en: 'Questions Count', tw: '題數' },
  questionAudio: { en: 'Question Audio', tw: '題目音檔' },
  questionImage: { en: 'Question Image', tw: '題目圖片' },
  questionSentence: {
    en: 'Please enter your sentence here...',
    tw: '題目單字/句子',
  },
  questionScreenshot: { en: 'Question Screenshot', tw: '題目縮圖' },
  questionText: { en: 'Question Text', tw: '題目文字' },
  rate: { en: 'rate', tw: '平均率' },
  reading: { en: 'Reading', tw: '閱讀' },
  red: { en: 'Red', tw: '長條紅' },
  redlight: { en: 'Red Light', tw: '紅色燈' },
  remark: { en: 'Remark', tw: '兌換備註' },
  remarkDefaultValue: {
    en: 'Please enter a comment for the exchange.',
    tw: '請輸入兌換備註',
  },
  remove: { en: 'Remove', tw: '移除' },
  removeStudent: { en: 'Remove Students From Classroom', tw: '從班級移除學生' },
  removeStudents: { en: 'Remove Students', tw: '移除學生' },
  replies: { en: 'Replies', tw: '答覆' },
  restart: { en: 'Restart', tw: '重新開始' },
  rewards: { en: 'Rewards', tw: '獎勵' },
  role: { en: 'Your role', tw: '你的身份' },
  requireSelectedSchool: {
    en: 'Required selected school.',
    tw: '需要選擇學校。',
  },
  requireSelectedClassroom: {
    en: 'Required selected classroom.',
    tw: '需要選擇教室。',
  },
  requireSelectedAssign: {
    en: 'Required selected Assign to classes or students.',
    tw: '需要選擇分配的類型。',
  },
  requireSelectedAssignees: {
    en: 'No assignees selected.',
    tw: '沒有選擇被分配學生或教室。',
  },
  requireSelectedDateStart: {
    en: 'Required selected date start.',
    tw: '需要填寫起始日期。',
  },
  requireSelectedDateEnd: {
    en: 'Required selected date end.',
    tw: '需要填寫截止日期。',
  },
  requireSelectedQuestions: {
    en: 'Number Of Questions not null or 0.',
    tw: '題目數量不能為空值或0。',
  },
  requireSelectedType: {
    en: 'Required selected a type.',
    tw: '需要選擇任務類型。',
  },
  requireSelectedActivity: {
    en: 'Required selected an activity.',
    tw: '需要選擇活動類型。',
  },
  requireSelectedProduct: {
    en: 'Required selected a product.',
    tw: '需要選擇產品。',
  },
  requireSelectedTitle: {
    en: 'Required selected a title.',
    tw: '需要選擇教材。',
  },
  requireSelectedLevel: {
    en: 'Required selected a level.',
    tw: '需要選擇等級。',
  },
  requireSelectedBook: {
    en: 'Required selected a book.',
    tw: '需要選擇書。',
  },
  requireSelectedUnit: {
    en: 'Required selected an unit.',
    tw: '需要選擇課程。',
  },
  save: { en: 'Save', tw: '儲存' },
  saveChange: { en: 'Save Changes', tw: '儲存修改' },
  savedText: { en: 'Saved Text', tw: '已存文字' },
  school: { en: 'School', tw: '學校' },
  schools: { en: 'Schools', tw: '學校' },
  score: { en: 'Score', tw: '成績' },
  scorelight: { en: 'Score Light', tw: '成績燈' },
  search: { en: 'Search', tw: '搜尋' },
  searchFileName: { en: 'Search file name...', tw: '搜尋檔名...' },
  searchStudent: {
    en: 'Search for student by username...',
    tw: '以帳號搜尋學生',
  },
  select: { en: 'Select', tw: '選擇' },
  selectA: { en: 'Select a', tw: '請選一個' },
  selectAllHomeworks: { en: 'Select All Homeworks', tw: '全選欲刪除的任務' },
  selectPrincipal: { en: 'Select a principal', tw: '選取校長' },
  selectTeacher: { en: 'Select a teacher', tw: '選取老師' },
  selectPrompt: {
    en: 'Plase select above info.',
    tw: '請選擇上方資訊。',
  },
  send: { en: 'Send', tw: '送出' },
  sendTo: { en: 'Send to', tw: '寄給' },
  sentence: { en: 'Sentence', tw: '句子' },
  sentenceEnter: {
    en: 'Click the ICON to enter the word/sentence...',
    tw: '點擊圖示以輸入單字/句子',
  },
  show: { en: 'Show ', tw: '顯示' },
  signin: { en: 'Log in', tw: '登入' },
  signInError: {
    en: 'Oh, no! Please check your username and password and try again.',
    tw: '請檢查您的帳號和密碼是否正確',
  },
  skill: { en: 'Skill', tw: '技能' },
  skillCategory: { en: 'Skill Category', tw: '技能類別' },
  skillHint: {
    en: 'Please specify the skill for this style or add a new skill. A skill is required for each style.',
    tw: '請為這個Style標記一項技能 或 新增一種技能. 技能必填',
  },
  songs: { en: 'Songs', tw: '影片歌曲' },
  speaking: { en: 'Speaking', tw: '口說' },
  speakingQuestionType: { en: 'speaking question type', tw: '口說題型' },
  speech: { en: 'Speech', tw: '演講' },
  specificClass: { en: 'Specific class(es)', tw: '特定班級' },
  specificStudent: { en: 'Specific student(s)', tw: '特定學生' },
  statistics: { en: 'Learning statistics', tw: '學習數據' },
  start: { en: 'start!', tw: '開始!' },
  story: { en: 'Story', tw: '故事' },
  student: { en: 'Student', tw: '學生' },
  student_s: { en: 'Student' + "'" + 's', tw: '學生的' },
  studentDetail: { en: 'StudentDetail', tw: '學生細節' },
  studentInterests: { en: 'STUDENT’S INTERESTS', tw: '學生興趣' },
  studentRecord: { en: 'Student Record', tw: '學生紀錄' },
  students: { en: 'Students', tw: '學生' },
  studentsDetail: { en: 'Students Detail', tw: '學生詳細資料' },
  studentAnswerAudio: { en: 'Student Answer Audio', tw: '學生錄音' },
  studentAnswerText: { en: 'Student Answer Text', tw: '學生作答' },
  studentsScore: { en: 'Students Score', tw: '學生成績' },
  studentCheckGradesDetailInfo: {
    en: 'Please select Product, Levle, Title and Book first!!',
    tw: '請先選擇產品、等級、標題及書本!!',
  },
  style: { en: 'Style', tw: '樣式' },
  stepUploadInfo: {
    en: 'if you need upload image, Please complete the relevant information.',
    tw: '如有需要上傳圖片，請填寫相關資料',
  },
  success: { en: 'Remove Success', tw: '成功移除' },
  summary: { en: 'Summary', tw: '總結' },
  sureDiscard: { en: 'Sure to discard the change to', tw: '放棄修改' },
  sureSave: { en: 'Sure to save the change to ', tw: '儲存修改' },
  switchPrincipalView: { en: 'Switch to Principal View', tw: '切換成校長視角' },
  switchTeacherView: { en: 'Switch to Teacher View', tw: '切換成老師視角' },
  teacher: { en: 'Teacher', tw: '老師' },
  teachers: { en: ' Teachers', tw: '老師' },
  tempRole: { en: ' Your temp role', tw: '你的暫時身分' },
  textNoEmpty: { en: "Text can't be empty!!!", tw: '文字不能為空！！！' },
  ticket: { en: 'Ticket', tw: '授權碼' },
  timestamp: { en: 'Timestamp', tw: '兌換時間' },
  withAppTicket: { en: 'withAppTicket', tw: '需要App授權碼' },
  canAssignHiddenStyles: {
    en: 'canAssignHiddenStyles',
    tw: '允許指派被隱藏的題目',
  },
  title: { en: 'Title', tw: '產品標題' },
  titles: { en: 'Products', tw: '產品(教材)' },
  titlesDetails: { en: 'Title Details', tw: '產品(教材)詳細' },
  toDate: { en: 'To date', tw: '終止日期' },
  total: { en: 'Total', tw: '現有' },
  totalScore: { en: 'Total', tw: '總計' },
  trash: { en: 'Trash', tw: '垃圾桶' },
  transactions: { en: 'Transactions', tw: '兌換紀錄' },
  unit: { en: 'Unit', tw: '單元' },
  unUsed: { en: 'Unused', tw: '未使用' },
  notAttempted: { en: `Not attempted`, tw: `未作答` },
  uploadAudio: { en: 'Upload Audio', tw: '上傳音檔' },
  uploadImage: { en: 'Upload Image', tw: '上傳圖片' },
  uploading: { en: 'Uploading...', tw: '上傳中...請稍等' },
  uploadVideo: { en: 'Upload Video', tw: '上傳影片' },
  username: { en: 'Username', tw: '帳號' },
  version: { en: 'Version', tw: '版本號' },
  viewStudentRecord: { en: 'View Student Record', tw: '檢視作答紀錄' },
  videoLibrary: { en: 'Video Library', tw: '影片庫' },
  videoForeign: { en: 'Video', tw: '外師影片' },
  videoUrl: { en: 'Video URL', tw: '影片網址' },
  viewmore: { en: 'View More', tw: '檢視更多' },
  visible: { en: 'Visible', tw: '可見' },
  invisible: { en: 'Invisible', tw: '不可見' },
  warning: {
    en: 'Warning! This action cannot be undone. Please review the details carefully before deleting.',
    tw: '警告! 刪除後無法回復，請確認是否刪除。',
  },
  warningClass: {
    en: 'NOTE: Class must NOT have any students before it can be deleted.',
    tw: '刪除班級前 請 移除班級內全部學生',
  },
  warningDuplicateLevel: {
    en: 'Warning! Duplicate level exist. Every level can be used on only one bookset. Please go to MyBestUser to fix max level limit of a bookset or use blank level temporarily!',
    tw: '警告! 這產品有等級差異, 等級不可重複, 每個書集合的等級必須不同, 請到 MyBestUser 修改最高級別限制 或 暫時使用空白等級! ',
  },
  whereToAddChecks: {
    en: 'Where do you want to add Check content to? Select below.',
    tw: '選擇位置以新增測驗',
  },
  whereToAddGames: {
    en: 'Where do you want to add GAMES content to? Select below.',
    tw: '選擇位置以新增遊戲',
  },
  whereToAddLearns: {
    en: 'Where do you want to add LEARN content to? Select below.',
    tw: '選擇位置以新增練習',
  },
  whereToAddMusics: {
    en: 'Where do you want to add MUSIC content to? Select below.',
    tw: '選擇位置以新增音樂',
  },
  word: { en: 'Word', tw: '單字' },
  word_sentences: { en: 'Words & Sentences', tw: '單字和句子' },
  writeMessage: { en: 'Write message', tw: '寫訊息' },
  writing: { en: 'Writing', tw: '寫作' },
  writingQuestionType: { en: 'writing question type', tw: '筆試題型' },
  yellowlight: { en: 'Yellow Light', tw: '黃色燈' },
});
