import { gql } from '@apollo/client';

export const GET_UNIT_NAME = gql`
  query SkWeb_GetUnitName($unitId: ID!) {
    unit(unitId: $unitId) {
      id
      title
    }
  }
`;
