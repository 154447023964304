import React from 'react';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';

function ErrorFallback({ error, resetErrorBoundary, history }) {
  const goBackToPrevious = () => {
    history.goBack();
    resetErrorBoundary();
  };
  return (
    <div>
      <h3>Sorry, an error has occurred: {error.message}</h3>
      <Button variant="contained" color="secondary" onClick={goBackToPrevious}>
        Go back
      </Button>
    </div>
  );
}

export default withRouter(ErrorFallback);
