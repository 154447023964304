import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_IMAGE = gql`
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetImage($fileId: ID!) {
    image(imageId: $fileId) {
      ...imageFrag
    }
  }
`;
