import { gql } from '@apollo/client';

export const GET_CHECKGRADE_ANALYSIS_LEARN_AND_CHECK_SCORE = gql`
  query SkWeb_checkGradeUsersDetail(
    $classId: ID!
    $bookId: ID!
    $activityType: String
    $studentId: ID
    $offset: Int
    $limit: Int
  ) {
    checkGradeUsersDetail(
      classId: $classId
      bookId: $bookId
      activityType: $activityType
      studentId: $studentId
      offset: $offset
      limit: $limit
    ) {
      id
      name
      unitScores
      unitTotalScore
      unitIds
      sectionIds
      styleIds
      unitTitles
    }
  }
`;
