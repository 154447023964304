import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_BOOKS = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_GetBooks($booksetId: ID!) {
    books(booksetId: $booksetId) {
      id
      title
      coverImage {
        ...coverimageFrag
      }
      withoutLock
      isHidden
      _bookset
      _units
      _tickets
    }
  }
`;
