import { gql } from '@apollo/client';

export const GET_CHECKGRADE_EXCEL_DATAS = gql`
  query SkWeb_checkGradeExcelDatas(
    $classId: ID!
    $titleId: ID!
    $level: String!
  ) {
    checkGradeExcelDatas(classId: $classId, titleId: $titleId, level: $level) {
      id
      title
      classId
      className
      numberOfStudents
      videos {
        id
        name
        username
        unitScores
        unitTotalScore
      }
      words {
        id
        name
        username
        unitScores
        unitTotalScore
      }
      checks {
        id
        name
        username
        unitScores
        unitTotalScore
      }
    }
  }
`;
