import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_UNITS = gql`
  query SkWeb_UnitsInBook($bookId: ID!) {
    units(bookId: $bookId) {
      id
      title
    }
  }
`;
