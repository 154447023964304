import { deepClone } from './commonUtils';

export const convertStyleData = (querydatas) => {
  const queryClone = deepClone(querydatas);

  if (queryClone.length) {
    return queryClone.map((data) => {
      data.id = undefined;
      data.__typename = undefined;
      data._audioData = data._audioData?.id;
      data._videoData = data._videoData?.id;
      data._imageData = data._imageData
        ? Array.isArray(data._imageData)
          ? data._imageData.map((image) => image?.id)
          : data._imageData?.id
        : [];

      if (data.lyricsData) {
        for (let i = 0; i < data.lyricsData.length; i++) {
          for (let j = 0; j < data.lyricsData[i].length; j++) {
            data.lyricsData[i][j].__typename = undefined;
            data.lyricsData[i][j]._img = data.lyricsData[i][j]._img?.id;
          }
        }
      }

      return data;
    });
  }
};

export const extractMedia = (media, keytype = 'key') => {
  if (media) {
    const instanceKey = Array.isArray(keytype)
      ? keytype.reduce((t, v) => t ?? media[v], undefined)
      : media[keytype];

    const { domain } = media;
    const key = instanceKey || media.key;

    if (domain && key) {
      if (Array.isArray(domain)) {
        // TODO: this is quick fix to use when sync the s3 to china
        return {
          src: domain[0] + encodeURI(key),
          srcSet: domain[1] + encodeURI(key),
        };
      } else {
        return domain + encodeURI(key);
      }
    }
  }

  return undefined;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};
