import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

export const DelayClickContext = createContext();

export const DelayClickProvider = ({ children }) => {
  const [disabled, setDisabled] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  return (
    <DelayClickContext.Provider
      value={{ disabled, setDisabled, loadingState, setLoadingState }}
    >
      {children}
    </DelayClickContext.Provider>
  );
};

export const useDisabled = () => [
  useContext(DelayClickContext).disabled,
  useContext(DelayClickContext).setDisabled,
];
export const useLoadingState = () => [
  useContext(DelayClickContext).loadingState,
  useContext(DelayClickContext).setLoadingState,
];

export const useDelayClick = () => {
  const [, setDisabled] = useDisabled();
  const [ownDisabled, setOwnDisabled] = useState(false);

  const delayClick = useCallback(
    (mutation, ...props) => () => {
      const propsLength = props.length < 2;
      props = propsLength ? props[0] : props;
      setOwnDisabled(true);
      mutation(props);
    },
    [],
  );

  useEffect(() => {
    let isMount = true;
    let delay;

    if (ownDisabled) {
      delay = setTimeout(() => {
        setOwnDisabled(false);
      }, 500);
    }
    isMount && setDisabled(ownDisabled);

    return () => {
      delay && clearTimeout(delay);
      isMount = false;
    };
  }, [ownDisabled, setDisabled]);

  return delayClick;
};
