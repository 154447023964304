import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_LEARN_STYLE = gql`
  ${FRAG.LEARN_DETAIL_FRAG}
  query SkWeb_GetStylelist($styleId: ID!) {
    style(styleId: $styleId) {
      ...learnDetailFrag
    }
  }
`;
