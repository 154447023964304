import { gql } from '@apollo/client';

export const GET_BOOK_NAMES = gql`
  query SkWeb_GetBooks($booksetId: ID!) {
    books(booksetId: $booksetId) {
      id
      title
    }
  }
`;
