import { gql } from '@apollo/client';

export const GET_HOMEWORK_SECTIONS = gql`
  query SkWeb_HomeworkSections($unitIds: [ID]) {
    homeworkSections(unitIds: $unitIds) {
      type
      id
      title
      _styles
    }
  }
`;
