import { gql } from '@apollo/client';

export const GET_FILTERSKILLS = gql`
  query SkWeb_FilterSkills($_mainSkill: ID, $_subSkill: ID) {
    filterSkills(_mainSkill: $_mainSkill, _subSkill: $_subSkill) {
      id
      name
      _subSkills
      subSkillname {
        id
        name
      }
    }
  }
`;
