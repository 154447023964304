const _configs = {};
let _mode = 'local';

export class ConfigHelperError extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'ConfigHelperError';
  }
}

const _throw = (message) => {
  throw new ConfigHelperError(message);
};

export const registerConfig = (name, options = {}) => {
  const { local, dev, prod, default: _default } = options;
  if (!name) _throw('Must register config with a name.');
  _configs[name] = { local, dev, prod, default: _default };
};

export const setDevMode = (mode) => (_mode = mode);
export const getDevMode = () => _mode;

export const getConfig = (name, options = {}) => {
  const { mode = _mode } = options;

  if (!_configs[name]) _throw('Config not registered.');
  if (process.env.NODE_ENV === 'production') {
    const host = window.location.hostname?.includes('dev') ? 'dev' : 'prod';
    return (
      _configs[name][host] ||
      _configs[name].default ||
      _throw(`No ${host} & default ${name} asigned.`)
    );
  }

  if (['local', 'dev', 'prod'].includes(mode)) {
    return (
      _configs[name][mode] ||
      _configs[name].default ||
      _throw(`No ${mode} & default ${name} asigned.`)
    );
  }

  return (
    _configs[name].prod ||
    _configs[name].dev ||
    _configs[name].local ||
    _configs[name].default ||
    _throw(`No ${name} asigned.`)
  );
};

export const isProduct =
  process.env.NODE_ENV === 'production' &&
  window.location.hostname?.includes('dev') === false;
