import { gql } from '@apollo/client';

export const GET_TITLES = gql`
  query SkWeb_Titles_2($productId: [ID!]) {
    titles(productId: $productId) {
      id
      title
    }
  }
`;
