import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const UPLOAD_AUDIO = gql`
  ${FRAG.AUDIO_FRAG}
  mutation SkWeb_uploadAudio(
    $audio: Upload!
    $titleId: ID
    $booksetId: ID
    $bookId: ID
    $unitId: ID
    $sectionId: ID
  ) {
    uploadAudio(
      audio: $audio
      titleId: $titleId
      booksetId: $booksetId
      bookId: $bookId
      unitId: $unitId
      sectionId: $sectionId
    ) {
      ...audioFrag
    }
  }
`;
