import { gql } from '@apollo/client';

export const GET_SCHOOL = gql`
  query SkWeb_School_3($schoolId: ID!) {
    school(schoolId: $schoolId) {
      id
      name
      teachers {
        id
        name
        username
      }
    }
  }
`;
