import { gql } from '@apollo/client';

export const GET_TEACHERS = gql`
  query SkWeb_Teacher(
    $offset: Int
    $limit: Int
    $adminId: ID
    $tempPrincipalId: ID
  ) {
    teachers(
      offset: $offset
      limit: $limit
      adminId: $adminId
      tempPrincipalId: $tempPrincipalId
    ) {
      id
      name
    }
  }
`;
