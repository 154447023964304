import { gql } from '@apollo/client';

export const GET_UPGRADE_CLASSROOM_INFO = gql`
  query SkWeb_UpgradeClassInfo(
    $classroomId: ID
    $productId: ID
    $level: String
  ) {
    upgradeClassInfo(
      classroomId: $classroomId
      productId: $productId
      level: $level
    ) {
      id
      name
      courses {
        level
        product {
          id
          name
        }
      }
      students {
        id
        name
      }
      bookset {
        id
        title
      }
    }
  }
`;
