import { gql } from '@apollo/client';

export const GET_CHECKGRADE_ANALYSIS_RATE = gql`
  query SkWeb_checkGradeAnalysisRate(
    $classId: ID!
    $titleId: ID!
    $productId: ID!
    $level: String!
  ) {
    checkGradeAnalysisRate(
      classId: $classId
      productId: $productId
      level: $level
      titleId: $titleId
    ) {
      videoRates {
        id
        title
        rate
      }
      wordRates {
        id
        title
        rate
      }
      checkRates {
        id
        title
        rate
      }
      finalRate {
        id
        productId
        level
        rate
      }
    }
  }
`;
