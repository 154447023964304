import { gql } from '@apollo/client';

export const GET_TEACHERS_IN_CLASSROOM = gql`
  query SkWeb_Classroom_2($classroomId: ID!) {
    classroom(classroomId: $classroomId) {
      id
      teachers {
        id
        name
      }
    }
  }
`;
