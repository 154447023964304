import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
// graphql
import {
  GET_ACTIVITY_NAME,
  GET_BOOK_NAME,
  GET_BOOKSET_NAME,
  GET_SECTION_IDS,
  GET_STYLE_NAME,
  GET_TITLE_NAME,
  GET_UNIT_NAME,
} from '../graphql/queries';

export const CurrentContext = createContext();

export const CurrentProvider = ({ children }) => {
  // hooks
  // 0:title, 1:bookset, 2:book, 3:unit
  const [currentIds, setCurrentIds] = useState([]);
  const [currentNames, setCurrentNames] = useState([]);
  const [currentStyleIndex, setCurrentStyleIndex] = useState(null);

  const [sectionId_learn, setSectionId_learn] = useState();
  const [sectionId_check, setSectionId_check] = useState();
  const [sectionId_games, setSectionId_games] = useState();
  const [sectionId_music, setSectionId_music] = useState();

  const [activityId, setActivityId] = useState();
  const [activityName, setActivityName] = useState();
  const [styleId, setStyleId] = useState();
  const [styleName, setStyleName] = useState();
  const [levels, setLevels] = useState({
    levelOptions: [], // dropdown options of level
    levelsOfBooksets: [], // be used levels
  });

  // graphql
  const { data: title } = useQuery(GET_TITLE_NAME, {
    variables: { titleId: currentIds[0] },
    skip: !currentIds[0],
  });
  const { data: bookset } = useQuery(GET_BOOKSET_NAME, {
    variables: { booksetId: currentIds[1] },
    skip: !currentIds[1],
  });
  const { data: book } = useQuery(GET_BOOK_NAME, {
    variables: { bookId: currentIds[2] },
    skip: !currentIds[2],
  });
  const { data: unit } = useQuery(GET_UNIT_NAME, {
    variables: { unitId: currentIds[3] },
    skip: !currentIds[3],
  });
  const { data: sections } = useQuery(GET_SECTION_IDS, {
    variables: { unitId: currentIds[3] },
    skip: !currentIds[3],
  });
  const { data: activity } = useQuery(GET_ACTIVITY_NAME, {
    variables: { activityId },
    skip: !activityId,
  });
  const { data: style } = useQuery(GET_STYLE_NAME, {
    variables: { styleId },
    skip: !styleId,
  });

  // set the current Names
  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setCurrentNames([
        title?.title.title,
        bookset?.bookset.title,
        book?.book.title,
        unit?.unit.title,
      ]);
    }

    return () => {
      isCurrent = false;
    };
  }, [title, bookset, book, unit]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (activity) {
        const { _title, _bookset, _book, _unit, title } = activity.activity;

        setActivityName(title);
        setCurrentIds([_title, _bookset, _book, _unit]);
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [activity]);

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (style) {
        const { _title, _bookset, _book, _unit, title } = style.style;

        setStyleName(title);
        setCurrentIds([_title, _bookset, _book, _unit]);
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [style]);

  // set the section ids
  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (currentIds[3] && sections) {
        const sectionIds = {};
        sections.sections.forEach((section) => {
          sectionIds[section.type] = section.id;
        });
        // db's changed from old:int to new:string
        setSectionId_learn(sectionIds.learn);
        setSectionId_check(sectionIds.check);
        setSectionId_games(sectionIds.playground);
        setSectionId_music(sectionIds.music);
      } else {
        setSectionId_learn(null);
        setSectionId_check(null);
        setSectionId_games(null);
        setSectionId_music(null);
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [currentIds, sections]);

  // set the levels
  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (title) {
        const { levelsOfBooksets } = levels;
        const levelOptions = title.title.levels;
        setLevels({ levelOptions, levelsOfBooksets });
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [title]);

  // main
  return (
    <CurrentContext.Provider
      value={{
        currentIds,
        setCurrentIds,
        currentNames,
        setCurrentNames,
        sectionId_learn,
        setSectionId_learn,
        sectionId_check,
        setSectionId_check,
        sectionId_games,
        setSectionId_games,
        sectionId_music,
        setSectionId_music,
        activityId,
        setActivityId,
        styleId,
        setStyleId,
        currentStyleIndex,
        setCurrentStyleIndex,
        activityName,
        setActivityName,
        styleName,
        setStyleName,
        levels,
        setLevels,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
};

export const useCurrentIds = () => [
  useContext(CurrentContext).currentIds,
  useContext(CurrentContext).setCurrentIds,
];

export const useCurrentNames = () => [
  useContext(CurrentContext).currentNames,
  useContext(CurrentContext).setCurrentNames,
];

export const useSectionId_learn = () => [
  useContext(CurrentContext).sectionId_learn,
  useContext(CurrentContext).setSectionId_learn,
];

export const useSectionId_check = () => [
  useContext(CurrentContext).sectionId_check,
  useContext(CurrentContext).setSectionId_check,
];

export const useSectionId_games = () => [
  useContext(CurrentContext).sectionId_games,
  useContext(CurrentContext).setSectionId_games,
];

export const useSectionId_music = () => [
  useContext(CurrentContext).sectionId_music,
  useContext(CurrentContext).setSectionId_music,
];

export const useActivityId = () => [
  useContext(CurrentContext).activityId,
  useContext(CurrentContext).setActivityId,
];

export const useStyleId = () => [
  useContext(CurrentContext).styleId,
  useContext(CurrentContext).setStyleId,
];

export const useCurrentStyleIndex = () => [
  useContext(CurrentContext).currentStyleIndex,
  useContext(CurrentContext).setCurrentStyleIndex,
];

export const useActivityName = () => [
  useContext(CurrentContext).activityName,
  useContext(CurrentContext).setActivityName,
];

export const useStyleName = () => [
  useContext(CurrentContext).styleName,
  useContext(CurrentContext).setStyleName,
];

export const useLevels = () => [
  useContext(CurrentContext).levels,
  useContext(CurrentContext).setLevels,
];
