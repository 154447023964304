import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_CLASSROOMS = gql`
  query SkWeb_Classrooms_9($schoolId: ID, $limit: Int, $offset: Int) {
    classrooms(schoolId: $schoolId, limit: $limit, offset: $offset) {
      id
      name
      courses {
        level
        product {
          id
          name
          payable
          withoutGrades
        }
      }
    }
  }
`;
