import { gql } from '@apollo/client';

export const GET_CLASSROOMS_DASHBOARD = gql`
  query SkWeb_Classrooms_1(
    $teacherId: ID
    $schoolId: ID
    $limit: Int
    $offset: Int
  ) {
    classrooms(
      teacherId: $teacherId
      schoolId: $schoolId
      limit: $limit
      offset: $offset
    ) {
      id
      name
      courses {
        level
        product {
          id
          name
          payable
        }
      }
      studentsLength
    }
  }
`;
