import defaultTheme from './defaultTheme';

const principalTheme = {
  ...defaultTheme,
  // primary: defaultTheme.green,
  // secondary: defaultTheme.greenSecondary,
  // tertiary: defaultTheme.greenTertiary,
};

export default principalTheme;
