import { gql } from '@apollo/client';

export const GET_PRODUCTOFSCHOOL = gql`
  query SkWeb_School_1($schoolId: ID!) {
    school(schoolId: $schoolId) {
      products {
        id
        name
      }
    }
  }
`;
