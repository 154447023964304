import { gql } from '@apollo/client';

export const GET_SCHOOLSDETAIL = gql`
  query SkWeb_SchoolsDetail {
    schools {
      name
      numberOfTeachers
      numberOfStudents
      products {
        id
        name
      }
      teachers {
        id
        products {
          id
          name
        }
      }
      students {
        products {
          id
          name
        }
      }
      classrooms {
        name
        studentsLength
        teacherLength
        highScore
        mediumScore
        lowScore
        courses {
          product {
            id
            name
          }
          level
        }
      }
    }
  }
`;
