import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_FINALTESTSCORES = gql`
  query SkWeb_testFinalScoreOfStudents(
    $classroomId: ID!
    $booksetId: ID!
    $type: String!
  ) {
    testFinalScoreOfStudents(
      classroomId: $classroomId
      booksetId: $booksetId
      type: $type
    ) {
      id
      writtenTestScore
    }
  }
`;
