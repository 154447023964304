import { gql } from '@apollo/client';

export const GET_HOMEWORK_LEARNSTYLE = gql`
  query SkWeb_Learnstyles($activityId: [ID!]) {
    learnstyles(activityId: $activityId) {
      id
      title
      isHidden
    }
  }
`;
