import { gql } from '@apollo/client';
import { AUDIO_FRAG } from '../../fragments';

export const GET_AUDIOS = gql`
  ${AUDIO_FRAG}
  query SkWeb_GetAudios(
    $titleId: ID
    $booksetId: ID
    $bookId: ID
    $unitId: ID
  ) {
    audios(
      titleId: $titleId
      booksetId: $booksetId
      bookId: $bookId
      unitId: $unitId
    ) {
      ...audioFrag
    }
  }
`;
