import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_CHECK_STYLES = gql`
  ${FRAG.STYLE_FRAG}
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetCheckstyledata($parentId: ID!) {
    checkstyles(sectionId: $parentId) {
      ...styleFrag
      image {
        ...imageFrag
      }
      _section
    }
  }
`;
