import defaultTheme from './defaultTheme';

const superTheme = {
  ...defaultTheme,
  // primary: defaultTheme.red,
  // secondary: defaultTheme.redSecondary,
  // tertiary: defaultTheme.redTertiary,
};

export default superTheme;
