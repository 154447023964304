import { gql } from '@apollo/client';

export const GET_ADMIN_TITLE_NAMES = gql`
  query SkWeb_Titles_3 {
    titles {
      id
      title
    }
  }
`;
