import { gql } from '@apollo/client';

export const GET_CHECKHOMEWORK_STUDENT_SCHOOL = gql`
  query SkWeb_Students_2($studentId: ID) {
    students(studentId: $studentId) {
      id
      name
      school {
        id
        name
      }
    }
  }
`;
