import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_BOOK_TRASH = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_bookTrash($booksetId: ID!) {
    bookTrash(booksetId: $booksetId) {
      id
      title
      coverImage {
        ...coverimageFrag
      }
      isHidden
      _bookset
      _units
    }
  }
`;
