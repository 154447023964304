import { gql } from '@apollo/client';

export const GET_CHECKHOMEWORK = gql`
  query SkWeb_CheckHomework(
    $username: String
    $schoolId: ID
    $productId: ID
    $classroomId: ID
    $offset: Int
    $limit: Int
    $titleId: ID
    $booksetIds: [ID]
  ) {
    checkHomework(
      username: $username
      schoolId: $schoolId
      productId: $productId
      classroomId: $classroomId
      offset: $offset
      limit: $limit
      titleId: $titleId
      booksetIds: $booksetIds
    ) {
      id
      userId
      name
      username
      classroom {
        id
        name
      }
      undoneHomeWork(titleId: $titleId, booksetIds: $booksetIds)
      expiredWithoutMakingUpHomeworks(titleId: $titleId, booksetIds: $booksetIds)
    }
  }
`;
