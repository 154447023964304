import { gql } from '@apollo/client';
import { BASIC_CLASSROOM_FRAG } from '../../fragments';

export const GET_CHECKGRADES = gql`
  ${BASIC_CLASSROOM_FRAG}
  query SkWeb_Classrooms_4(
    $schoolId: ID
    $classroomIds: [ID!]
    $teacherId: ID
    $offset: Int
    $limit: Int
  ) {
    classrooms(
      classroomIds: $classroomIds
      schoolId: $schoolId
      teacherId: $teacherId
      offset: $offset
      limit: $limit
    ) {
      ...BasicClassroomFrag
    }
  }
`;
