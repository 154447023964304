import { gql } from '@apollo/client';

export const GET_CLASSROOMSDETAIL = gql`
  query SkWeb_Classrooms_3($schoolId: ID, $limit: Int, $offset: Int) {
    classrooms(schoolId: $schoolId, limit: $limit, offset: $offset) {
      id
      name
      school {
        id
        name
      }
      courses {
        level
        product {
          id # cause page reload, but can't remove it
          name
          payable
          baseLevel
          levels
          isCustomLevel
          customLevels
          withoutGrades
        }
      }
      teachers {
        id
        name
        username
      }
      students {
        id
        name
      }
      studentsLength
      highScore
      mediumScore
      lowScore
    }
  }
`;
