import { gql } from '@apollo/client';

export const GET_INSIGHT_DETAIL = gql`
  query SkWeb_InsightsDetail($classId: ID!, $offset: Int, $limit: Int) {
    insightsDetail(classId: $classId, offset: $offset, limit: $limit) {
      id
      name
      username
      videosRate
      abcsRate
      animationRate
      storyRate
      songsRate
      word_sentencesRate
    }
  }
`;
