import { gql } from '@apollo/client';

export const GET_STUDENTLESSON = gql`
  query SkWeb_studentLesson($userId: ID) {
    studentLesson(userId: $userId) {
      id
      name
      learnProgress
      learnScore
      checkProgress
      checkScore
    }
    students(studentId: $userId) {
      avgCheckGrade
      avgLearnGrade
    }
  }
`;
