import { gql } from '@apollo/client';

export const GET_TITLE_NAME = gql`
  query SkWeb_GetTitleName($titleId: ID!) {
    title(titleId: $titleId) {
      id
      title
      levels(titleId: $titleId) {
        name
        level
      }
    }
  }
`;
