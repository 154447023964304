import { gql } from '@apollo/client';

export const HOMEWORK_FRAG = gql`
  fragment HomeworkFrag on Homework {
    id
    student {
      id
      name
      username
    }
    title {
      id
      title
      _product
    }
    book {
      id
      title
    }
    bookset {
      id
      title
      level
    }
    unit {
      id
      title
    }
    section {
      id
      title
    }
    activitys {
      id
      title
    }
    styles {
      id
      title
    }
    type
    assignedDate
    dueDate
    doneDate
    progress
    hwGrade
    isFinished
    makeUp
  }
`;
