import { gql } from '@apollo/client';

export const GET_CHECKHOMEWORK_FILTER = gql`
  query SkWeb_CheckHomeworkFilter {
    classrooms {
      id
      name
    }
    products {
      id
      name
    }
    schools {
      id
      name
    }
  }
`;
