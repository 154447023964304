import { gql } from '@apollo/client';

export const GET_SECTION_IDS = gql`
  query SkWeb_GetSectionIds($unitId: ID!) {
    sections(unitId: $unitId) {
      id
      type
    }
  }
`;
