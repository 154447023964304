// utils
import { registerConfig } from '../../utils/configHelpers';

registerConfig('mybestuser', {
  local: 'https://dev.mybestuser.com', //local 'http://localhost:5566',
  // local: 'https://mybestuser.cn', //prod
  dev: 'https://dev.mybestuser.com',
  // prod: 'https://mybestuser.cn',
  prod:
    window.location.href.indexOf('cn') !== -1
      ? 'https://mybestuser.cn'
      : 'https://mybestuser.com',
});

registerConfig('clientId', {
  local: '7f28bd1fb85791438a64', //local
  // local: '28577522b2909226d9a8', //prod
  dev: '7f28bd1fb85791438a64',
  prod: '28577522b2909226d9a8',
});
