import { gql } from '@apollo/client';

export const GET_ADMIN_TITLE = gql`
  query SkWeb_Title_1($titleId: ID!) {
    title(titleId: $titleId) {
      id
      title
    }
  }
`;
