import { gql } from '@apollo/client';

export const GET_ASSIGNMENT_FILTERS = gql`
  query SkWeb_AssignmentFilters($schoolId: ID) {
    schools {
      id
      name
      classrooms {
        id
        name
        courses {
          level
          product {
            id
            name
            payable
          }
        }
      }
    }
    teachers {
      id
      name
    }
    classrooms(schoolId: $schoolId) {
      id
      name
      courses {
        level
        product {
          id
          name
          payable
        }
      }
    }
  }
`;
