import { gql } from '@apollo/client';

export const GET_SECTION = gql`
  query SkWeb_GetSection($sectionId: ID!) {
    section(sectionId: $sectionId) {
      id
      _title
      _bookset
      _book
      _unit
    }
  }
`;
