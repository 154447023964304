import { gql } from '@apollo/client';

export const GET_STYLE_NAME = gql`
  query SkWeb_GetStyleName($styleId: ID!) {
    style(styleId: $styleId) {
      id
      title
      _title
      _bookset
      _book
      _unit
    }
  }
`;
