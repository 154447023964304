import { gql } from '@apollo/client';

export const GET_CHECKGRADES_AVAILABLE_LEVELS = gql`
  query SkWeb_upgradeClassInfo(
    $classroomId: ID
    $productId: ID
    $level: String
  ) {
    upgradeClassInfo(
      classroomId: $classroomId
      productId: $productId
      level: $level
    ) {
      ownAvailableLevels {
        name
        level
      }
    }
  }
`;
