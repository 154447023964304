import { createTheme as createThemeV5 } from '@mui/material/styles';

export const adminThemeV5 = createThemeV5({
  typography: {
    fontSize: 15,
    button: { fontSize: 15, fontWeight: 'bold' },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
  palette: {
    text: {
      primary: '#9061bc', // ListItemText color
      secondary: '#9061bc',
      tertiary: '#fff', // not use yet
    },
    primary: {
      main: '#9061bc',
      light: '#a680c9',
      dark: '#644383',
      contrastText: '#fff',
    },
    secondary: {
      main: '#dec6f3',
      light: '#e4d1f5',
      dark: '#9b8aaa',
      contrastText: '#9061bc',
    },
    tertiary: {
      // not use yet
      main: '#fff',
      contrastText: '#000',
    },
    delete: {
      main: '#f50057',
      contrastText: '#fff',
    },
    action: {
      active: '#9061bc', // ListItemIcon color
      hover: '#fff', // ListItem backgroundColor
      selected: '#fff',
    },
    background: {
      paper: '#f2f5fA', // List backgroundColor
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#644383',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-selectLabel': {
            marginBottom: 0,
            fontWeight: 'bold',
          },
          '& .MuiTablePagination-displayedRows': {
            marginBottom: 0,
            fontWeight: 'bold',
          },
        },
        selectLabel: {
          fontSize: 16,
        },
        displayedRows: {
          fontSize: 16,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#dec6f3',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
              backgroundColor: '#fff',
            },
            '&:hover': {
              backgroundColor: '#a680c9',
              '& .MuiTableCell-root': {
                color: '#fff',
              },
              '& .MuiChip-root': {
                color: '#fff',
                borderColor: '#fff',
              },
              '& .MuiLink-root': {
                color: '#fff',
              },
              '& .MuiIconButton-root': {
                color: '#fff',
              },
            },
            '& .MuiTableCell-root': {
              color: '#000',
            },
          },
        },
      },
    },
    // for Third-party: mui-datatables
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          backgroundColor: '#dec6f3',
        },
        contentWrapper: {
          justifyContent: 'center',
        },
        toolButton: {
          justifyContent: 'center',
          fontSize: 16,
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          fontSize: 18,
        },
      },
    },

    MUIDataTablePagination: {
      styleOverrides: {
        navContainer: {
          justifyContent: 'center',
        },
        toolbar: {
          fontSize: 16,
        },
      },
    },
  },
});
