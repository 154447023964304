import { gql } from '@apollo/client';

export const GET_SUBSKILLS = gql`
  query SkWeb_GetSubSkills($mainSkillIds: [ID!]) {
    subSkills(mainSkillIds: $mainSkillIds) {
      id
      name
      _mainSkill
    }
  }
`;
