import { gql } from '@apollo/client';

export const GET_LEARNS = gql`
  query SkWeb_GetLearnsdata($sectionId: ID!) {
    admin_learns(sectionId: $sectionId) {
      id
      title
      type
      isHidden
      _styles
    }
  }
`;
