import { gql } from '@apollo/client';

export const GET_CHECKGRADE_ANALYSIS_FINALSCORE = gql`
  query SkWeb_checkGradeUsersFinalDetail(
    $classId: ID!
    $productId: ID!
    $booksetId: ID!
    $studentId: ID
  ) {
    checkGradeUsersFinalDetail(
      classId: $classId
      productId: $productId
      booksetId: $booksetId
      studentId: $studentId
    ) {
      id
      name
      username
      finalScore
      unitScores {
        id
        title
        score
      }
    }
  }
`;
