import { gql } from '@apollo/client';

// fragment
import { ASSIGNMENT_FRAG } from '../../fragments';

export const GET_ASSIGNMENTS = gql`
  ${ASSIGNMENT_FRAG}
  query SkWeb_Assignments(
    $username: String
    $schoolId: ID
    $classroomId: ID
    $assignerId: ID
    $dateFrom: DateTime
    $dateTo: DateTime
    $offset: Int
    $limit: Int
  ) {
    assignments(
      username: $username
      schoolId: $schoolId
      classroomId: $classroomId
      assignerId: $assignerId
      dateFrom: $dateFrom
      dateTo: $dateTo
      offset: $offset
      limit: $limit
    ) {
      ...AssignmentFrag
      startDate
      homeworks {
        id
        isFinished
        doneDate # some date is null on DB
      }
    }
  }
`;
