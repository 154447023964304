import { gql } from '@apollo/client';

export const GET_SKILL_PERFORMANCE = gql`
  query SkApp_UserSkillPerformance($userId: ID) {
    skillPerformance(userId: $userId) {
      id
      name
      performance
    }
  }
`;
