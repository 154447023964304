import { gql } from '@apollo/client';

export const GET_LEVELS = gql`
  query SkWeb_GetLevels($titleId: ID!) {
    levels(titleId: $titleId) {
      name
      level
    }
  }
`;
