import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
// import { createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

// utils
import { getConfig, isProduct } from '../../utils';

const errorFlow = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        //`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        '[GraphQL error]: Message: ',
        message,
        ', Location: ',
        locations,
        ', Path: ',
        path,
      ),
    );
  if (networkError) {
    // alert('Disconnect with server!');
    localStorage.setItem('server', networkError);
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// //websocket link
const skowtLink = createUploadLink({
  uri: `${getConfig('skowtserver')}/graphql`,
});

// WebSocket endpoint (used for subscriptions)
const skowtWsLink = new GraphQLWsLink(
  createClient({
    url: `${getConfig('skowtserver-ws')}/graphql`,
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      authorization: localStorage.getItem('accessToken'),
    }),
  }),
);
// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  skowtWsLink,
  skowtLink,
);

//loadmore pagination
const offsetLimitPagination = ({ keyArgs = [] } = {}) => {
  const read = (existing, { args: { offset, limit } }) => {
    if (limit !== undefined && offset !== undefined) {
      return existing && existing.slice(0, offset + existing?.length);
    }
    return existing;
  };

  const merge = (
    existing = [],
    incoming,
    {
      args: {
        offset = 0, //...rest
      } = {},
      readField,
    } = {},
  ) => {
    try {
      const merged = existing ? existing.slice(0) : [];
      // remove Duplicated
      const existingIdSet = new Set(
        merged.map((exist) => readField('id', exist)),
      );
      incoming = incoming?.filter(
        (income) => !existingIdSet.has(readField('id', income)),
      );
      // add to existing from incoming
      for (let i = 0; i < incoming.length; ++i) {
        offset = existingIdSet.size; // add new incoming data to the bottom
        merged[offset + i] = incoming[i];
      }
      return merged;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    keyArgs,
    read,
    merge,
  };
};

const mergeNonNormalized = {
  // prefer incoming over existing
  // now after mutation, refetch is called
  merge: false,
};

const cache = new InMemoryCache({
  typePolicies: {
    Me: {
      keyFields: ['userId'],
    },
    CheckHomework: {
      keyFields: ['id', 'userId'],
    },
    Query: {
      queryType: true,
      fields: {
        titles: mergeNonNormalized,
        titlesTrash: mergeNonNormalized,
        learnstyleTrash: mergeNonNormalized,
        checkstyleTrash: mergeNonNormalized,
        levels: mergeNonNormalized,
        checkstyles: mergeNonNormalized,
        learnstyles: mergeNonNormalized,
        classrooms: offsetLimitPagination({
          keyArgs: ['schoolId', 'productId', 'classroomIds', 'teacherId'],
        }),
        insights: offsetLimitPagination({
          keyArgs: ['schoolId', 'productId', 'classroomIds', 'teacherId'],
        }),
        students: offsetLimitPagination({
          keyArgs: [
            'username',
            'studentId',
            'schoolId',
            'productId',
            'classroomId',
          ],
        }),
        assignments: offsetLimitPagination({
          keyArgs: [
            'username',
            'schoolId',
            'classroomId',
            'assignerId',
            'dateFrom',
            'dateTo',
          ],
        }),
        messages: offsetLimitPagination({
          keyArgs: ['schoolId', 'classroomId', 'dateFrom', 'dateTo'],
        }),
        replyMessages: offsetLimitPagination({
          keyArgs: ['messageId'],
        }),
        checkHomework: offsetLimitPagination({
          keyArgs: [
            'username',
            'schoolId',
            'productId',
            'classroomId',
            'titleId',
            'booksetIds',
          ],
        }),
        insightsDetail: offsetLimitPagination({
          keyArgs: ['classId'],
        }),
        homeworkDetail: offsetLimitPagination({
          keyArgs: ['userId', 'status', 'titleId', 'booksetIds'],
        }),
        insightsAnalysis: offsetLimitPagination({ keyArgs: false }),
        classroomHomework: offsetLimitPagination({
          keyArgs: ['schoolId'],
        }),
        teachers: offsetLimitPagination({
          keyArgs: ['schoolId', 'tempPrincipalId'],
        }),
        checkGradeUsersDetail: offsetLimitPagination({
          keyArgs: ['activityType', 'bookId', 'classId'],
        }),
      },
    },
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  // mutate: {
  //   fetchPolicy: "network-only",
  //   errorPolicy: "all",
  // },
};

export const client = new ApolloClient({
  link: isProduct
    ? ApolloLink.from([authLink, splitLink])
    : ApolloLink.from([errorFlow, authLink, splitLink]),

  // link: authLink.concat(skowtLink),
  cache,
  defaultOptions,
});
