import { gql } from '@apollo/client';
import { BASIC_CLASSROOM_FRAG } from '../../fragments';

export const GET_CLASSROOM = gql`
  ${BASIC_CLASSROOM_FRAG}
  query SkWeb_Classroom_1($classroomId: ID!) {
    classroom(classroomId: $classroomId) {
      ...BasicClassroomFrag
      students {
        id
        name
      }
    }
  }
`;
