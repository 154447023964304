import { gql } from '@apollo/client';

export const GET_HOMEWORK_UNITS = gql`
  query SkWeb_Units($bookId: ID!) {
    units(bookId: $bookId) {
      id
      title
      noScore
      isHidden
    }
  }
`;
