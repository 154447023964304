import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

// images
import { ReactComponent as IMG_LOADING } from '../../loading.svg';

// main
const LoadingModal = React.forwardRef(({ loading = false, sx }, ref) => {
  const [progress, setProgress] = React.useState(null);

  React.useImperativeHandle(ref, () => {
    return {
      updateProgress: (newProgress) => {
        setProgress(newProgress);
      }
    }
  }, []);

  return (
    // main
    <Modal open={loading}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '10rem',
          ...sx,
        }}
      >
        <SvgIcon component={IMG_LOADING} inheritViewBox fontSize="inherit" />
        {
          typeof progress === 'number' && 
          <Typography variant="body1" component={Box} fontSize="24px" fontWeight="bold" color="#FFF" position="absolute">
            {`${progress}%`}
          </Typography>
        }
      </Box>
    </Modal>
  );
});
export default LoadingModal;
