import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_AUDIO = gql`
  ${FRAG.AUDIO_FRAG}
  query SkWeb_GetAudio($fileId: ID!) {
    audio(audioId: $fileId) {
      ...audioFrag
    }
  }
`;
