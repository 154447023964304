import { gql } from '@apollo/client';

export const GET_HOMEWORK_SCHOOLS = gql`
  query SkWeb_Schools_4 {
    schools {
      id
      name
      teachers {
        id
        name
      }
    }
  }
`;
