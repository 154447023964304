import { gql } from '@apollo/client';

export const GET_ME = gql`
  query SkWeb_getme {
    me {
      userId
      name
      username
      role
      avatarUri
      #numberOfStudents
      products {
        id
        name
        payable
        withoutGrades
      }
      #schools {
      #  id
      #  name
      #numberOfClassrooms
      #classrooms {
      #TODO: need fix if user have many classrooms
      #  id
      #  name
      #}
      #}
      #classrooms {
      #TODO: need fix if user have many classrooms
      #  id
      #  name
      #}
      editableProductIds
    }
  }
`;
