import { gql } from '@apollo/client';

export const GET_HOMEWORK_CLASSROOMS = gql`
  query SkWeb_Classrooms_5($schoolId: ID, $teacherId: ID, $principalId: ID) {
    classrooms(
      schoolId: $schoolId
      teacherId: $teacherId
      principalId: $principalId
    ) {
      id
      name
      school {
        id
        name
      }
      teachers {
        id
        name
        username
      }
      students {
        id
        name
        username
      }
    }
  }
`;
