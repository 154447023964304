import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query SkWeb_Products {
    products {
      id
      name
    }
  }
`;
