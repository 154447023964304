import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AlertDialog = ({ close, message, options }) => {
  const { type, closeCopy } = options;
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      onClose={close}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        sx={{
          color: `${type}.light`,
          fontWeight: 'bold',
        }}
      >
        {type === 'error' && (
          <>
            <ErrorIcon sx={{ color: `${type}.light` }} /> Error
          </>
        )}
        {type === 'info' && (
          <>
            <InfoIcon sx={{ color: `${type}.light` }} /> Info
          </>
        )}
        {type === 'success' && (
          <>
            <CheckCircleIcon sx={{ color: `${type}.light` }} /> Success
          </>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-slide-description"
          variant="h6"
          sx={{ color: `${type}.light` }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            close();
          }}
          sx={{ color: `${type}.light` }}
        >
          {closeCopy || 'Okay'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
