import { gql } from '@apollo/client';

export const GET_MESSAGES_CLASSROOMS = gql`
  query SkWeb_Classrooms_7($schoolId: ID, $classroomIds: [ID!]) {
    classrooms(schoolId: $schoolId, classroomIds: $classroomIds) {
      id
      name
      students {
        id
        name
      }
    }
  }
`;
