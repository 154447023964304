import { gql } from '@apollo/client';

export const GET_STUDENT_STATE = gql`
  query SkWeb_StudnetState(
    $userId: ID!
    $titleId: ID
    $booksetId: ID
    $bookId: ID
    $unitId: ID
  ) {
    studentState(
      userId: $userId
      titleId: $titleId
      booksetId: $booksetId
      bookId: $bookId
      unitId: $unitId
    ) {
      type
      activitys {
        id
        title
      }
      progress
      grade
      dateDone
    }
  }
`;
