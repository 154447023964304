import { gql } from '@apollo/client';

export const GET_BOOKSET_NAME = gql`
  query SkWeb_GetBooksetName($booksetId: ID!) {
    bookset(booksetId: $booksetId) {
      id
      title
    }
  }
`;
