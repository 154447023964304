import { gql } from '@apollo/client';

export const GET_STUDENT_IN_SCHOOLS = gql`
  query SkWeb_School_2($schoolId: ID!) {
    school(schoolId: $schoolId) {
      students {
        id
        name
        username
      }
      products {
        id
        name
      }
    }
  }
`;
