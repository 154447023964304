import { gql } from '@apollo/client';

export const GET_ACTIVITY = gql`
  query SkWeb_Get_activity($activityId: ID!) {
    activity(activityId: $activityId) {
      id
      title
      _title
      _bookset
      _book
      _unit
      _section
    }
  }
`;
