//ref: GET_STYLE_VIEW.js

import { gql } from '@apollo/client';

export const GET_HOMEWORK_STYLE_VIEW = gql`
  query SkWeb_GetHomeworkStyleView(
    $styleIds: [ID!]
    $userId: ID!
    $homeworkId: ID!
  ) {
    styles(styleIds: $styleIds) {
      id
      title
      _title
      _bookset
      _book
      _unit
      _section
      path
      image {
        id
        #name
        domain
        key
      }
      answers
      questions {
        videoData
        textData
        lyricsData {
          _img {
            id
            name
          }
          word
        }
        _audioData {
          id
          domain
          key
        }
      }
      homeworkStyleView(userId: $userId, homeworkId: $homeworkId) {
        id
        texts
        highlights
        _audios {
          id
          name
          key
          domain
        }
        updatedAt
      }
    }
  }
`;
