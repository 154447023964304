import { gql } from '@apollo/client';

export const GET_STUDENTS_TICKETS = gql`
  query SkWeb_StudentsTickets($studentsId: [ID!], $offset: Int, $limit: Int) {
    studentsTickets(studentsId: $studentsId, offset: $offset, limit: $limit) {
      tickets {
        id
        name
        status
      }
    }
  }
`;
