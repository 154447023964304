import { gql } from '@apollo/client';

export const GET_HOMEWORK_CHECKSTYLE = gql`
  query SkWeb_homeworkCheckstyles($sectionIds: [ID]) {
    homeworkCheckstyles(sectionIds: $sectionIds) {
      id
      title
      isHidden
      mainSkills {
        id
        name
      }
    }
  }
`;
