import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_CHECK_STYLE = gql`
  ${FRAG.CHECK_DETAIL_FRAG}
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetStylelist($styleId: ID!) {
    style(styleId: $styleId) {
      ...checkDetailFrag
      image {
        ...imageFrag
      }
    }
  }
`;
