import { gql } from '@apollo/client';

export const GET_HOMEWORK_BOOKS = gql`
  query SkWeb_Books($booksetId: ID!) {
    books(booksetId: $booksetId) {
      id
      title
      withoutLock
    }
  }
`;
