import { gql } from '@apollo/client';

export const GET_STYLE_VIEW = gql`
  query SkWeb_GetStyleView($styleIds: [ID!], $userId: ID!, $homeworkId: ID) {
    styles(styleIds: $styleIds) {
      id
      title
      #_title
      #_bookset
      #_book
      _unit
      #_section
      path
      image {
        id
        #name
        domain
        key
      }
      answers
      questions {
        id
        videoData
        textData
        lyricsData {
          _img {
            id
            name
          }
          word
        }
        _audioData {
          id
          domain
          key
        }
        _imageData {
          id
          domain
          key
        }
        _videoData {
          id
          domain
          key
        }
      }
      options {
        id
        textData
        _audioData {
          id
          domain
          key
        }
        _imageData {
          id
          domain
          key
        }
      }
      styleView(userId: $userId, homeworkId: $homeworkId) {
        id
        texts
        #highlights
        _audios {
          id
          name
          key
          domain
        }
        percentages
        isCorrect
        updatedAt
      }
      styleType
    }
  }
`;
