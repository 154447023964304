import { gql } from '@apollo/client';

export const GET_HOMEWORK_TITLES = gql`
  query SkWeb_Titles_1($productId: [ID!]) {
    titles(productId: $productId) {
      id
      title
      canAssignHiddenStyles
    }
  }
`;
