import { gql } from '@apollo/client';

export const GET_HOMEWORK_LEARNS = gql`
  query SkWeb_homeworkLearns($sectionIds: [ID]) {
    homeworkLearns(sectionIds: $sectionIds) {
      id
      title
    }
  }
`;
