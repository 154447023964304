import { gql } from '@apollo/client';

export const GET_STUDENTS_ID = gql`
  query SkWeb_Students_6(
    $username: String
    $schoolId: ID
    $productId: ID
    $classroomId: ID
    $offset: Int
    $limit: Int
  ) {
    students(
      username: $username
      schoolId: $schoolId
      productId: $productId
      classroomId: $classroomId
      offset: $offset
      limit: $limit
    ) {
      id
      name
      username
      englishName
    }
  }
`;
