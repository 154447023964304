import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_CHECKGRADE_DETAIL_HEADER = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_checkGradeDetailHeader(
    $bookId: ID!
    $sectionTypes: [String]
    $activityType: String
  ) {
    checkGradeDetailHeader(bookId: $bookId, activityType: $activityType) {
      title
      id
      _book
      _sections
      sections(sectionTypes: $sectionTypes) {
        type
        _styles
        activities {
          type
          _styles
        }
      }
      isHidden
      coverImage {
        ...coverimageFrag
      }
    }
  }
`;
