import { gql } from '@apollo/client';

export const GET_ALL_TICKETS = gql`
  query SkWeb_GetTickets {
    tickets {
      id
      name
    }
  }
`;
