import { gql } from '@apollo/client';

export const GET_SECTIONS = gql`
  query SkWeb_GetSectiondata($unitId: ID!) {
    sections(unitId: $unitId) {
      title
      id
      isHidden
      _styles
    }
  }
`;
