import { gql } from '@apollo/client';

export const GET_MUSIC_TRASH = gql`
  query SkWeb_GetMusicTrash($sectionId: ID!) {
    musicTrash(sectionId: $sectionId) {
      id
      name
      size
      domain
      key
      createdAt
      status
    }
  }
`;
