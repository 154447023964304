import { gql } from '@apollo/client';

export const GET_SCHOOLS_DASHBOARD = gql`
  query SkWeb_Schools_1 {
    schools {
      id
      name
      numberOfTeachers
      numberOfStudents
      numberOfClassrooms
      products {
        id
        name
      }
      contracts {
        id
        startsAt
        endsAt
        products {
          id
          name
        }
      }
    }
  }
`;
