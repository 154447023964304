import { gql } from '@apollo/client';

export const COVERIMAGE_FRAG = gql`
  fragment coverimageFrag on Image {
    id
    small
    domain
    key
    status
  }
`;

export const IMAGE_FRAG = gql`
  fragment imageFrag on Image {
    id
    name
    domain
    key
    thumbnail
    small
    status
  }
`;

export const AUDIO_FRAG = gql`
  fragment audioFrag on Audio {
    id
    name
    domain
    key
    status
  }
`;

export const VIDEO_FRAG = gql`
  fragment videoFrag on Video {
    id
    name
    domain
    key
    status
    thumbnail {
      id
      domain
      small
    }
  }
`;

export const VIDEO_WITHOUT_THUMBNAIL_FRAG = gql`
  fragment videoFragNoThumbnail on Video {
    id
    name
    domain
    key
    status
  }
`;
