import { gql } from '@apollo/client';

// fragment
import { HOMEWORK_FRAG } from '../../fragments';

export const GET_HOMEWORK_DETAIL = gql`
  query SkWeb_HomeworkDetail(
    $userId: ID
    $status: HomeworkStatus
    $limit: Int
    $offset: Int
    $titleId: ID
    $booksetIds: [ID]
  ) {
    homeworkDetail(
      userId: $userId
      status: $status
      limit: $limit
      offset: $offset
      titleId: $titleId
      booksetIds: $booksetIds
    ) {
      ... on Homework {
        ...HomeworkFrag
      }
    }
  }
  ${HOMEWORK_FRAG}
`;
