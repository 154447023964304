import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_ADMIN_TITLES = gql`
  ${FRAG.COVERIMAGE_FRAG}
  query SkWeb_Titles_4 {
    titles {
      id
      _product
      title
      isHidden
      withAppTicket
      canAssignHiddenStyles
      coverImage {
        ...coverimageFrag
      }
    }
  }
`;
