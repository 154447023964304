import { gql } from '@apollo/client';

export const GET_STUDENTS_OF_CLASSROOM = gql`
  query SkWeb_Students_4($classroomId: ID, $studentId: ID) {
    students(classroomId: $classroomId, studentId: $studentId) {
      classroom {
        id
        name
      }
    }
  }
`;
