export const handleError = (error) => {
  if (error.response) {
    const detail = `detail: ${error.response.data.error}` || '';
    const statusCode = error.response.data.code || error.response.status;
    if (
      parseInt(error.response.status, 10) === 401 ||
      parseInt(error.response.status, 10) === 403
    )
      return console.error(statusCode, 'Need authorization!', detail);

    return console.error(statusCode, detail);
  }
  if (error.request) {
    return console.error('Server no response.');
  }
  return console.error(error.message);
};
