import { gql } from '@apollo/client';

export const GET_CHECKGRADE_BOOKSETS = gql`
  query SkWeb_Booksets_3(
    $titleId: ID!
    $level: String
    $isEqual: Boolean
    $noScore: Boolean
    $isHidden: Boolean
  ) {
    booksets(
      titleId: $titleId
      level: $level
      isEqual: $isEqual
      isHidden: $isHidden
    ) {
      id
      title
      books(isHidden: $isHidden) {
        id
        title
        isHidden
        withoutLock
        units(noScore: $noScore, isHidden: $isHidden) {
          id
          title
          isHidden
          noScore
        }
      }
    }
  }
`;
