import { gql } from '@apollo/client';

export const GET_PRODUCT_OF_CLASSROOM = gql`
  query SkWeb_getProductOfClassroom($classroomId: ID!) {
    getProductOfClassroom(classroomId: $classroomId) {
      id
      name
      level
      payable
      withoutGrades
    }
  }
`;
