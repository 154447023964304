import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import AlertTemplate from './components/@elements/AlertTemplate';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { useQuery } from '@apollo/client';
// graphql
import { GET_ME } from './graphql/queries';
// hooks
import {
  useAuth,
  useRole,
  useUserType,
  useName,
  useUsername,
  useAuthChecker,
  useAvatarUri,
  useUserId,
  useUserProducts,
  useUserEditableProductIds,
} from './hooks/authHooks';

// configs
import { themeSelector } from './configs/themes';
// error
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './error/FallbackComponent';

import { LanguageProvider } from './utils/languageHelpers';
import { AudioProvider } from './hooks/audioHooks';
import LoadingModal from './components/@elements/LoadingModal';

const LandingPage = lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ './pages/LandingPage'),
);
const AdminRoute = lazy(() =>
  import(/* webpackChunkName: "AdminRoute" */ './pages/AdminRoute'),
);
const PrincipalRoute = lazy(() =>
  import(/* webpackChunkName: "PrincipalRoute" */ './pages/PrincipalRoute'),
);
const TeacherRoute = lazy(() =>
  import(/* webpackChunkName: "TeacherRoute" */ './pages/TeacherRoute'),
);
const PublicPage2 = lazy(() =>
  import(/* webpackChunkName: "PublicPage2" */ './pages/PublicPage2'),
);
const TeammsPage = lazy(() =>
  import(/* webpackChunkName: "TeammsPage" */ './pages/TeammsPage'),
);
const MyBestUserLogin = lazy(() =>
  import(/* webpackChunkName: "MyBestUserLogin" */ './pages/MyBestUserLogin'),
);
const Privacy = lazy(() =>
  import(/* webpackChunkName: "Privacy" */ './pages/Privacy'),
);
const PrivacyChildren = lazy(() =>
  import(/* webpackChunkName: "Privacy" */ './pages/PrivacyChildren'),
);
const PrivacyGlobal = lazy(() =>
  import(/* webpackChunkName: "Privacy" */ './pages/PrivacyGlobal'),
);
const Share = lazy(() =>
  import(/* webpackChunkName: "Share" */ './pages/Share'),
);
const ShareRoute = lazy(() =>
  import(/* webpackChunkName: "Share" */ './pages/Share/ShareRoute'),
);
const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFound" */ './pages/NotFound'),
);
const Agreement = lazy(() =>
  import(/* webpackChunkName: "Agreement" */ './pages/Agreement'),
);

// main
const App = () => {
  const [, setUserId] = useUserId();

  const { loading, data } = useQuery(GET_ME, {
    skip: !localStorage.getItem('accessToken'),
  });
  const [, setAuth] = useAuth();
  const [, setRole] = useRole();
  const [, setName] = useName();
  const [, setAvatarUri] = useAvatarUri();
  const [, setUsername] = useUsername();
  const [userType] = useUserType();
  const [, setUserProducts] = useUserProducts();
  const [, setUserEditableProductIds] = useUserEditableProductIds();

  const authChecker = useAuthChecker();

  useEffect(() => {
    if (!loading) {
      if (data) {
        setAuth('pass');
        setRole(data?.me?.role);
        setName(data?.me?.name);
        setAvatarUri(data?.me?.avatarUri);
        setUsername(data?.me?.username);
        setUserId(data?.me?.userId);
        setUserProducts(data?.me?.products);
        setUserEditableProductIds(data?.me?.editableProductIds);
      } else setAuth('fail');
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAppError = (error, componentStack) => {
    // TODO: log the error to a file
    console.log(error, componentStack);
  };

  return (
    <ThemeProvider theme={themeSelector(userType)}>
      <LanguageProvider>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <AudioProvider>
            <GlobalStyle />
            <Router>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => window.location.reload()}
                onError={handleAppError}
              >
                <Suspense fallback={<LoadingModal loading={true} />}>
                  {/* set the route */}
                  <Switch>
                    <Route path="/" exact component={LandingPage} />

                    <Route
                      path="/super"
                      component={authChecker('super', AdminRoute)}
                    />
                    <Route
                      path="/admin"
                      component={authChecker('admin', AdminRoute)}
                    />
                    <Route
                      path="/developer"
                      component={authChecker('developer', AdminRoute)}
                    />

                    <Route
                      path="/principal"
                      component={authChecker('principal', PrincipalRoute)}
                    />
                    <Route
                      path="/teacher"
                      component={authChecker('teacher', TeacherRoute)}
                    />
                    <Route
                      path="/secretary"
                      component={authChecker('secretary', TeacherRoute)}
                    />

                    <Route path="/app" component={PublicPage2} />

                    {/* FOR TEAMMS APP */}
                    <Route path="/teamms" component={TeammsPage} />

                    <Route
                      path="/mybestuser"
                      exact
                      component={MyBestUserLogin}
                    />

                    <Route path="/privacy" component={Privacy} />

                    <Route path="/privacychild" component={PrivacyChildren} />

                    <Route path="/privacypolicy" component={PrivacyGlobal} />

                    <Route path="/agreement" component={Agreement} />

                    <Route path="/shareV1" component={Share} />
                    <Route path="/share" component={ShareRoute} />

                    <Route path="*" component={NotFound} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </AudioProvider>
        </AlertProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;

// GlobalStyle
const GlobalStyle = createGlobalStyle`
html {
  font-size:12px;
}
html,body,#root {
  width: 100%;
  height: 100%;
}
img,button{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
textarea,input,button{
  :focus {
    outline: none;
  }
}
`;

// alert option
const alertOptions = {
  // timeout: 2000,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: 100000,
  },
  transitions: transitions.FADE,
};
