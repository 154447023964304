import { gql } from '@apollo/client';

export const GET_UNIT_NAMES = gql`
  query SkWeb_GetUnitdata($bookId: ID!) {
    units(bookId: $bookId) {
      title
      id
    }
  }
`;
