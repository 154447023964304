import { gql } from '@apollo/client';
import { BASIC_CLASSROOM_FRAG } from '../../fragments';

export const GET_INSIGHTS = gql`
  ${BASIC_CLASSROOM_FRAG}
  query SkWeb_Insights(
    $schoolId: ID
    $classroomIds: [ID!]
    $teacherId: ID
    $principalId: ID
    $offset: Int
    $limit: Int
  ) {
    insights(
      principalId: $principalId
      teacherId: $teacherId
      schoolId: $schoolId
      classroomIds: $classroomIds
      offset: $offset
      limit: $limit
    ) {
      ...BasicClassroomFrag
    }
  }
`;
