import { gql } from '@apollo/client';

export const GET_CHECKGRADE_EXCEL_FINALDATA = gql`
  query SkWeb_checkGradeExcelFinalData(
    $classId: ID!
    $productId: ID!
    $titleId: ID!
    $level: String!
  ) {
    checkGradeExcelFinalData(
      classId: $classId
      productId: $productId
      titleId: $titleId
      level: $level
    ) {
      id
      name
      username
      finalScore
      unitScores {
        id
        title
        score
      }
    }
  }
`;
