import React from 'react';
import Dialog from '@mui/material/Dialog';

const SignInDialog = ({ src, onClose }) => (
  <Dialog open={Boolean(src)} onClose={onClose} maxWidth="sm" fullWidth>
    <iframe
      src={src}
      title="My Best User Sign In"
      style={{ border: 'none', minHeight: '70vh' }}
    />
  </Dialog>
);

export default SignInDialog;
