import { gql } from '@apollo/client';

export const GET_BOOK_NAME = gql`
  query SkWeb_GetBookName($bookId: ID!) {
    book(bookId: $bookId) {
      id
      title
    }
  }
`;
