import { gql } from '@apollo/client';
import * as FRAG from '../../fragments';

export const GET_IMAGES = gql`
  ${FRAG.IMAGE_FRAG}
  query SkWeb_GetImages(
    $productId: [ID]
    $titleId: ID
    $booksetId: ID
    $bookId: ID
    $unitId: ID
  ) {
    images(
      productId: $productId
      titleId: $titleId
      booksetId: $booksetId
      bookId: $bookId
      unitId: $unitId
    ) {
      ...imageFrag
    }
  }
`;
