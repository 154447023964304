import { some } from 'lodash-es';

export const modifyUrl = (url) =>
  url.slice(-1) === '/' ? url.slice(0, -1) : url;

export const deepClone = (data) => JSON.parse(JSON.stringify(data));

export const getRandomAlphabetArr = (amount) => {
  const arr = [];
  for (let i = 0; i < amount; i++) {
    arr.push(String.fromCharCode(Math.floor(Math.random() * 25 + 97)));
  }
  return arr;
};

export const regSliceStr = (str) => {
  return /[a-zA-Z]+/i.exec(str)[0];
};
export const getUrlofTargetNextId = (url, key) => {
  if (!url) return;
  const urlArr = url.split('/');
  const getTargetIndex = urlArr.findIndex((e) => e === key);
  return urlArr[getTargetIndex + 1];
};

export const resortArray = (
  orderArr,
  targetArr,
  processOrder,
  processTarget,
) => {
  const orderIndex = (t) =>
    orderArr.findIndex(
      (o) =>
        (processOrder ? processOrder(o) : o) ===
        (processTarget ? processTarget(t) : t),
    );

  return targetArr.sort((a, b) => (orderIndex(a) > orderIndex(b) ? 1 : -1));
};

// some error for this one
export const numberToFraction = (amount) => {
  // This is a whole number and doesn't need modification.
  if (parseFloat(amount) === parseInt(amount)) {
    return amount;
  }
  // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
  const gcd = function (a, b) {
    if (b < 0.0000001) {
      return a;
    }
    return gcd(b, Math.floor(a % b));
  };
  const len = amount.toString().length - 2;
  let denominator = Math.pow(10, len);
  let numerator = amount * denominator;
  let base = 0;
  const divisor = gcd(numerator, denominator);

  numerator /= divisor;
  denominator /= divisor;
  // In a scenario like 3/2, convert to 1 1/2
  // by pulling out the base number and reducing the numerator.
  if (numerator > denominator) {
    base = Math.floor(numerator / denominator);
    numerator -= base * denominator;
  }
  amount = `${Math.floor(numerator)}/${Math.floor(denominator)}`;
  if (base) {
    amount = `${base} ${amount}`;
  }
  return amount;
};

export const getFraction = (decimal) => {
  for (var denominator = 1; (decimal * denominator) % 1 !== 0; denominator++);
  return { numerator: decimal * denominator, denominator };
};

export const getIntegerRatio = (decimal) => {
  const { numerator, denominator } = getFraction(decimal);
  return `${numerator} : ${denominator}`;
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getNeedStr = (str, cutoff) =>
  str.substr(str.indexOf(cutoff, -1) + 1);

export const getHasDuplicates = (array) => {
  const earlierMatch = (elt, index, array) => array.indexOf(elt) !== index;
  return some(array, earlierMatch);
};

//It's one-based, so 1 is A, 26 is Z, 27 is AA.
//For export calculate Excel the cell place.
export const toLetters = (num) => {
  var mod = num % 26,
    pow = (num / 26) | 0,
    out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? toLetters(pow) + out : out;
};

export const vttStars = (percent) => {
  let star = 0;

  if (percent >= 90 && percent <= 100) {
    star = 5;
  } else if (percent >= 75 && percent < 90) {
    star = 4;
  } else if (percent >= 60 && percent < 75) {
    star = 3;
  } else if (percent >= 30 && percent < 60) {
    star = 2;
  } else if (percent >= 0 && percent < 30) {
    star = 1;
  } else {
    star = 0;
  }

  return star;
};
