import { gql } from '@apollo/client';

export const GET_MESSAGES_FILTER = gql`
  query SkWeb_MessagesFilter {
    schools {
      id
      name
    }
    classrooms {
      id
      name
    }
  }
`;
