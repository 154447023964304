import { gql } from '@apollo/client';

export const GET_CHECKGRADESDEATIL = gql`
  query SkWeb_Students_1(
    $classroomId: ID
    $username: String
    $studentId: ID
    $offset: Int
    $limit: Int # $pageIndex: Int
  ) {
    students(
      classroomId: $classroomId
      username: $username
      studentId: $studentId
      offset: $offset
      limit: $limit # pageIndex: $pageIndex
    ) {
      id
      name
      username
      # avgLearnProgress
      # avgLearnGrade
      # avgCheckProgress
      # avgCheckGrade
      product {
        id
        name
      }
    }
  }
`;
