import { gql } from '@apollo/client';

export const GET_UPGRADELEVEL_COURSES = gql`
  query SkWeb_getCourses($classroomId: ID!, $withoutGrades: Boolean) {
    getCourses(classroomId: $classroomId, withoutGrades: $withoutGrades) {
      level
      product {
        id
        name
        withoutGrades
      }
    }
  }
`;
