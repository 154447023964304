// utils
import { registerConfig } from '../../utils/configHelpers';

registerConfig('skowtserver', {
  local: 'http://localhost',
  dev: 'https://api.dev.skowt.io',
  prod: 'https://api.linux2.skowt.cn',
});

registerConfig('skowtserver-ws', {
  local: 'ws://localhost',
  dev: 'wss://api.dev.skowt.io',
  prod: 'wss://api.linux2.skowt.cn',
});

registerConfig('uploadserver', {
  local: 'https://dev.upload.mybestta.com',
  dev: 'https://dev.upload.mybestta.com',
  prod: 'https://upload.mybestuser.cn',
});

registerConfig('skowtuploads-s3', {
  local: 'https://dev.media.skowt.io',
  dev: 'https://dev.media.skowt.io',
  prod: window.location.hostname.includes('skowt.cn')
    ? 'https://media.skowt.cn'
    : 'https://media.skowt.io',
});
